
let initialState = {
  lead: {},
};

const leadReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
};

export default leadReducer;
