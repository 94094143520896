import * as actionTypes from "../constants/staff";

let initialState = {
  list: [],
  staffs: [],
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_STAFF_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
      };

    case actionTypes.SET_STAFFS:
      return {
        ...state,
        loading: false,
        staffs: action.payload,
      };

    default:
      return initialState;
  }
};

export default staffReducer;
