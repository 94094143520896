export const CREATE_MODEL_REQUEST = `CREATE_MODEL_REQUEST`;
export const CREATE_MODEL_SUCCESS = `CREATE_MODEL_SUCCESS`;

export const UPDATE_MODEL_REQUEST = `UPDATE_MODEL_REQUEST`;
export const UPDATE_MODEL_SUCCESS = `UPDATE_MODEL_SUCCESS`;

export const DELETE_MODEL_REQUEST = `DELETE_MODEL_REQUEST`;
export const DELETE_MODEL_FAILED = `DELETE_MODEL_FAILED`;

export const SET_ACTIVE_MODEL = `SET_ACTIVE_MODEL`;

export const GET_FAVORITE_MODELS = `GET_FAVORITE_MODELS`;
export const SET_FAVORITE_MODELS = `SET_FAVORITE_MODELS`;

export const FAVORITE_MODEL = "FAVORITE_MODEL";
export const UNFAVORITE_MODEL = "UNFAVORITE_MODEL";
export const UPDATE_FAVORITE_MODEL = "UPDATE_FAVORITE_MODEL";

export const GET_PROJECT_MODELS = "GET_PROJECT_MODELS";
export const SET_PROJECT_MODELS = "SET_PROJECT_MODELS";
