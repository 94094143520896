import API from "./";

export const GetAllClient = () => API.get("/clients/");

// export const getProjectService = (id) => API.get(`/projects/${id}/`);
export const GetClientService = (id) => API.get(`/clients/${id}/`);

export const UpdateClientService = (id, payload) =>
  API.put(`/clients/${id}/`, payload);


export const GetAllVendorService = () => API.get("/vendors/");

export const GetVendorService = (id) => API.get(`/vendors/${id}/`);

export const UpdateVendorService = (id, payload) =>
  API.put(`/vendors/${id}/`, payload);
