import { useState } from "react";

import Modal from "react-bootstrap/Modal";

const SaveTourModal = (props) => {
  const { isOpen, setModalState, SubmitFormHandler } = props;
  const [layoutName, setLayoutName] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    SubmitFormHandler(layoutName);
  };

  return (
    <Modal show={isOpen} centered onHide={() => setModalState(false)}>
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-5 pb-5">
        <h1 className="mb-4">Save As...</h1>
        <form onSubmit={submitHandler}>
          <div className="mb-5">
            <label htmlFor="" className="form-label">
              Layout Name
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Layout Name"
              value={layoutName}
              onChange={(e) => setLayoutName(e.target.value)}
              required
            />
          </div>
          <div className="d-grid mb-4">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SaveTourModal;
