import { Store as store } from "react-notifications-component";

export const CreateSuccessNotification = (message = "Success!") => {
  store.addNotification({
    message,
    type: "success",
    container: "top-right", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
      duration: 3000,
    },
  });
};

export const CreateErrorNotification = (message = "Error!") => {
  store.addNotification({
    message,
    type: "danger",
    container: "top-right", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
      duration: 3000,
    },
  });
};

export const CreateInfoNotification = (message = "Info!") => {
  store.addNotification({
    message,
    type: "info",
    container: "top-right", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
      duration: 3000,
    },
  });
};
