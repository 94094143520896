const JSONfromSuperAdmin = {
  version: "0.0.1",
  name: "ARIA 2",
  rooms: [
    {
      name: "Kitchen",
      isTour: true,
      initialPosition: { pitch: 0, yaw: 180 },
      defaultScene: "/360-images/kitchen/kitchen-transitional.png",
      defaultPreset: "Transitional",
      presets: [
        {
          name: "Transitional",
          imgPreview: "/360-images/kitchen/kitchen-transitional-thumbnail.png",
        },
        {
          name: "Mid-Century",
          imgPreview: "/360-images/kitchen/kitchen-mid-century-thumbnail.png",
        },
        {
          name: "Contemporary",
          imgPreview: "/360-images/kitchen/kitchen-contemporary-thumbnail.png",
        },
        {
          name: "Modern",
          imgPreview: "/360-images/kitchen/kitchen-modern-thumbnail.png",
        },
      ],
      items: {
        defaults: [
          {
            name: "Backsplash",
            styles: [
              {
                name: "LANTERN WHITE GLOSS",
                retailPrice: 10,
                buildPrice: 20,
                imgPreview: "/360-images/kitchen/style_variations/backsplash-lantern-white-gloss.jpg",
                selected: true,
              },
              {
                name: "HERRINGBONE WHITE",
                retailPrice: 10,
                buildPrice: 20,
                imgPreview: "/360-images/kitchen/style_variations/backsplash-herringbone-white.jpg",
                selected: false,
              },
              {
                name: "CALACATTA",
                retailPrice: 10,
                buildPrice: 20,
                imgPreview: "/360-images/kitchen/style_variations/backsplash-calacatta.jpg",
                selected: false,
              },
              {
                name: "SUBWAY",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/backsplash-subway-straight-stack-black-grout.jpg",
                selected: false,
              },
            ],
            position: { pitch: 185.601186210937403, yaw: 5.645239582939126 },
          },
          {
            name: "Countertop",
            styles: [
              {
                name: "TAGA",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/countertop-taga.jpg",
                selected: true,
              },
              {
                name: "CALACATTA",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/countertop-calacatta.jpg",
                selected: false,
              },
              {
                name: "ETERNAL MARQUINA",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/countertop-eternal-marquina.jpg",
                selected: false,
              },
            ],
            position: { pitch: 203.115343550402239, yaw: 25.2981077593787679 },
          },
          {
            name: "Faucet",
            styles: [
              {
                name: "CHROME",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/faucet-chrome.jpg",
                selected: true,
              },
              {
                name: "BRUSHED NICKEL",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/faucet-brushed-nickel.jpg",
                selected: false,
              },
              {
                name: "MATTE BLACK",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/faucet-matte-black.jpg",
                selected: false,
              },
              {
                name: "STAINLESS STEEL",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/faucet-stainless-steel.jpg",
                selected: false,
              },
            ],
            position: { pitch: -17, yaw: 6 },
          },
          {
            name: "Floor",
            styles: [
              {
                name: "TIMBERWOLF",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/floor-timberwolf.jpg",
                selected: true,
              },
              {
                name: "COUNTESS 1011",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/floor-countess-1011.jpg",
                selected: false,
              },
              {
                name: "MIDNIGHT",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/floor-midnight.png",
                selected: false,
              },
              {
                name: "STLYE II - PURE WHITE POLISHED",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "/360-images/kitchen/style_variations/floor-style-II-pure-white-polished.jpg",
                selected: false,
              },
            ],
            position: { pitch: 225.115343550402239, yaw: 60.5981077593787679 },
          },
        ],
        custom: [
          {
            presetName: "Transitional",
            items: [
              {
                name: "Cabinet",
                styles: [
                  {
                    name: "WHITE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-white.jpg",
                    selected: true,
                  },
                  {
                    name: "GREY",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-grey-transitional.jpg",
                    selected: false,
                  },
                  {
                    name: "STONE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-stone.jpg",
                    selected: false,
                  },
                  {
                    name: "WALNUT",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-walnut.jpg",
                    selected: false,
                  },
                  {
                    name: "ESPRESSO",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-espresso.jpg",
                    selected: false,
                  },
                ],
                position: { pitch: 170.601186210937403, yaw: 26.645239582939126 },
              }
            ],
          },
          {
            presetName: "Mid-Century",
            items: [
              {
                name: "Cabinet",
                styles: [
                  {
                    name: "SILVER OAK",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-silver-oak.jpg",
                    selected: true,
                  },
                  {
                    name: "BLACK WALNUT",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-black-walnut.jpg",
                    selected: false,
                  },
                  {
                    name: "OAK",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-oak.jpg",
                    selected: false,
                  },
                  {
                    name: "MILLENIUM",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-millenium.jpg",
                    selected: false,
                  },
                  {
                    name: "ROSEWOOD",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-rosewood.jpg",
                    selected: false,
                  },
                ],
                position: { pitch: 170.601186210937403, yaw: 26.645239582939126 },
              }
            ],
          },
          {
            presetName: "Contemporary",
            items: [
              {
                name: "Cabinet",
                styles: [
                  {
                    name: "WHITE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-white.jpg",
                    selected: true,
                  },
                  {
                    name: "GREY",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-grey-transitional.jpg",
                    selected: false,
                  },
                  {
                    name: "STONE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-stone.jpg",
                    selected: false,
                  },
                  {
                    name: "WALNUT",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-walnut.jpg",
                    selected: false,
                  },
                  {
                    name: "ESPRESSO",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-espresso.jpg",
                    selected: false,
                  },
                ],
                position: { pitch: 170.601186210937403, yaw: 26.645239582939126 },
              }
            ],
          },
          {
            presetName: "Modern",
            items: [
              {
                name: "Cabinet",
                styles: [
                  {
                    name: "PURE WHITE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-pure-white.jpg",
                    selected: true,
                  },
                  {
                    name: "METALLIC BEIGE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-metallic-beige.jpg",
                    selected: false,
                  },
                  {
                    name: "GREY",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-grey-modern.jpg",
                    selected: false,
                  },
                  {
                    name: "BRONZE",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "/360-images/kitchen/style_variations/cabinet-bronze.jpg",
                    selected: false,
                  },
                  // {
                  //   name: "STONE",
                  //   retailPrice: 0,
                  //   buildPrice: 0,
                  //   imgPreview: "/360-images/kitchen/style_variations/cabinet-stone.jpg",
                  //   selected: false,
                  // },
                ],
                position: { pitch: 170.601186210937403, yaw: 26.645239582939126 },
              }
            ],
          },
        ],
      },
      combinations: [
        {
          name: "Transitional",
          image: "/360-images/kitchen/kitchen-transitional-compressed.png",
          initialPosition: { pitch: 0, yaw: 180 },
        },
        {
          name: "Mid-Century",
          image: "/360-images/kitchen/kitchen-mid-century-compressed.png",
          initialPosition: { pitch: 0, yaw: 180 },
        },
        {
          name: "Contemporary",
          image: "/360-images/kitchen/kitchen-contemporary-compressed.png",
          initialPosition: { pitch: 0, yaw: 180 },
        },
        {
          name: "Modern",
          image: "/360-images/kitchen/kitchen-modern-compressed.png",
          initialPosition: { pitch: 0, yaw: 180 },
        },
        // {
        //   items: [
        //     { name: "Wall", style: "WHITE" },
        //     { name: "Backsplash", style: "WHITE" },
        //   ],
        //   image: "/360-images/Countertop/countertop_white_white.png",
        // },
        // {
        //   items: [
        //     { name: "Wall", style: "WHITE" },
        //     { name: "Backsplash", style: "BROWN" },
        //   ],
        //   image: "/360-images/Countertop/countertop_white_brown.png",
        // },
        // {
        //   items: [
        //     { name: "Wall", style: "BROWN" },
        //     { name: "Backsplash", style: "WHITE" },
        //   ],
        //   image: "/360-images/Countertop/countertop_brown_white.png",
        // },
        
        // {
        //   items: [
        //     { name: "Wall", style: "WHITE" },
        //     { name: "Backsplash", style: "WHITE" },
        //   ],
        //   image: "/360-images/Countertop/countertop_white_white.png",
        //   //image: "https://pannellum.org/images/cerro-toco-0.jpg",
        // },
      ],
    },
    {
      name: "Living Room",
      isTour: false,
      defaultScene: "/360-images/magnolia-living-room.jpeg",
      defaultPreset: "preset_name",
      initialPosition: { pitch: 0, yaw: 180 },
      presets: [
        {
          name: "preset_name",
          imgPreview: "img_path",
        },
      ],
      items: {
        defaults: [
          {
            name: "hotspot_name",
            styles: [
              {
                name: "style_name",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "img_path",
                selected: true,
              },
            ],
            position: { pitch: 0, yaw: 180 },
          },
        ],
        custom: [
          {
            name: "preset_name",
            items: [
              {
                name: "hotspot_name",
                styles: [
                  {
                    name: "style_name",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "img_path",
                    selected: true,
                  },
                ],
                position: { pitch: 0, yaw: 180 },
              },
            ],
          }
        ],
      },
      combinations: [],
    },
    {
      name: "Family Room",
      isTour: false,
      defaultScene: "/360-images/magnolia-family-room.jpeg",
      defaultPreset: "preset_name",
      initialPosition: { pitch: 0, yaw: 180 },
      presets: [
        {
          name: "preset_name",
          imgPreview: "img_path",
        },
      ],
      items: {
        defaults: [
          {
            name: "hotspot_name",
            styles: [
              {
                name: "style_name",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "img_path",
                selected: true,
              },
            ],
            position: { pitch: 0, yaw: 180 },
          },
        ],
        custom: [
          {
            name: "preset_name",
            items: [
              {
                name: "hotspot_name",
                styles: [
                  {
                    name: "style_name",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "img_path",
                    selected: true,
                  },
                ],
                position: { pitch: 0, yaw: 180 },
              },
            ],
          }
        ],
      },
      combinations: [],
    },
    {
      name: "Main Bedroom",
      isTour: false,
      defaultScene: "/360-images/magnolia-main-bedroom.jpeg",
      defaultPreset: "preset_name",
      initialPosition: { pitch: 0, yaw: 180 },
      presets: [
        {
          name: "preset_name",
          imgPreview: "img_path",
        },
      ],
      items: {
        defaults: [
          {
            name: "hotspot_name",
            styles: [
              {
                name: "style_name",
                retailPrice: 0,
                buildPrice: 0,
                imgPreview: "img_path",
                selected: true,
              },
            ],
            position: { pitch: 0, yaw: 180 },
          },
        ],
        custom: [
          {
            name: "preset_name",
            items: [
              {
                name: "hotspot_name",
                styles: [
                  {
                    name: "style_name",
                    retailPrice: 0,
                    buildPrice: 0,
                    imgPreview: "img_path",
                    selected: true,
                  },
                ],
                position: { pitch: 0, yaw: 180 },
              },
            ],
          }
        ],
      },
      combinations: [],
    },
  ],
};

export default JSONfromSuperAdmin;
