import { call, takeLatest, all, put } from "redux-saga/effects";

import * as actionTypes from "store/constants/inventory";
import * as InventoryService from "services/inventoryServices";

import {
  CreateInventoryItemSuccess,
  SetInventoryItems,
  SetInventoryColorOptions,
  SetInventoryItemTypeOptions,
  SetInventoryStyleOptions,
  UpdateInventorySuccess,
  DeleteInventorySuccess,
} from "store/actions/inventory";

function* CreateNewInventoryItemFlow(action) {
  const { data, resolve, reject } = action.payload;

  const { err, res } = yield call(InventoryService.createItemService, data);
  if (err) {
    return reject(err);
  }

  resolve();
  yield put(CreateInventoryItemSuccess(res.data));
}

function* GetInventoryItemsFlow() {
  const { err, res } = yield call(InventoryService.getInventoryItemsService);

  if (err) {
    return console.log(err);
  }

  yield put(SetInventoryItems(res.data));
}

function* GetInventoryOptionsFlow() {
  const colors = yield call(InventoryService.getInventoryColorOptionsService);
  const styles = yield call(InventoryService.getInventoryStyleOptionsService);
  const types = yield call(InventoryService.getInventoyItemTypeOptionsService);

  yield put(
    SetInventoryColorOptions(
      colors.res.data.map((color) => ({ value: color.name, label: color.name }))
    )
  );
  yield put(
    SetInventoryStyleOptions(
      styles.res.data.map((style) => ({ value: style.name, label: style.name }))
    )
  );
  yield put(
    SetInventoryItemTypeOptions(
      types.res.data.map((type) => ({ value: type.name, label: type.name }))
    )
  );
}

function* UpdateInvetoryItemFlow(action) {
  const { data, id, resolve, reject } = action.payload;
  const { err, res } = yield call(
    InventoryService.updateInventoryItemService,
    id,
    data
  );

  if (err) {
    return reject(err);
  }
  yield put(UpdateInventorySuccess(res.data));
  resolve();
}

function* DeleteInventoryFlow(action) {
  const { id } = action.payload;
  const { err, res } = yield call(InventoryService.deleteInventoryItem, id);
  if (err) {
    return;
    //show notif here to show error
  }

  yield put(DeleteInventorySuccess({ id }));
}

function* ActionWatcher() {
  yield takeLatest(
    actionTypes.CREATE_INVENTORY_ITEM_REQUEST,
    CreateNewInventoryItemFlow
  );
  yield takeLatest(actionTypes.GET_INVENTORY_ITEMS, GetInventoryItemsFlow);
  yield takeLatest(actionTypes.GET_INVENTORY_OPTIONS, GetInventoryOptionsFlow);
  yield takeLatest(
    actionTypes.UPDATE_INVENTORY_ITEM_REQUEST,
    UpdateInvetoryItemFlow
  );
  yield takeLatest(
    actionTypes.DELETE_INVENTORY_ITEM_REQUEST,
    DeleteInventoryFlow
  );
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
