import * as AUTH_ from "store/constants/auth";

export const AuthLoginRequest = (payload) => ({
  type: AUTH_.LOGIN_REQUEST,
  payload,
});

export const ForgotPasswordRequest = (payload) => ({
  type: AUTH_.FORGOT_PASSWORD_REQUEST,
  payload,
});

export const VerifyForgotPasswordCode = (payload) => ({
  type: AUTH_.VERIFY_FORGOT_PASSWORD_CODE,
  payload,
});

export const ChangePassword = (payload) => ({
  type: AUTH_.CHANGE_PASSWORD,
  payload,
});

export const RegisterRequest = (payload) => ({
  type: AUTH_.REGISTER_REQUEST,
  payload,
});

export const VerifyRegisterRequest = (payload) => ({
  type: AUTH_.VERIFY_REGISTER_REQUEST,
  payload,
});

export const ResendRegisterVerifyCode = (payload) => ({
  type: AUTH_.RESEND_REGISTER_VERIFY_CODE,
  payload,
});

export const UpdateUserSettingRequest = (payload) => ({
  type: AUTH_.UPADATE_USER_SETTINGS_REQUEST,
  payload,
});

export const UpdateUserPasswordRequest = (payload) => ({
  type: AUTH_.UPDATE_USER_PASSWORD_REQUEST,
  payload,
});

export const Logout = () => ({
  type: AUTH_.LOGOUT,
});

export const SetShowLoginModal = (payload) => ({
  type: AUTH_.SET_SHOW_LOGIN_MODAL,
  payload,
});

export const RegisterVendorRequest = (payload) => ({
  type: AUTH_.REGISTER_VENDOR_REQUEST,
  payload,
});

// export const VerifyRegisterVendorRequest = (payload) => ({
//   type: AUTH_.VERIFY_REGISTER_VENDOR_REQUEST,
//   payload,
// });

// export const ResendRegisterVendorVerifyCode = (payload) => ({
//   type: AUTH_.RESEND_REGISTER_VENDOR_VERIFY_CODE,
//   payload,
// });
