import { call, takeLatest, all, put } from "redux-saga/effects";

import {
  loginService,
  forgotPasswordRequest,
  forgotPasswordConfirm,
  changePassword,
  registerService,
  updateUserSettings,
  updateUserPassword,
  resendRegVerifyCodeService,
  verifyEmailService,
  registerVendorService,
} from "services/authServices";
import * as AUTH_ from "store/constants/auth";
import { setUser } from "store/actions/user";
import { userDetailRequest } from "store/actions/user";

import { setAuthHeaderToken } from "services";

export function* LoginFlow(action) {
  const { email, password, resolve, reject } = action.payload;

  const { err, res } = yield call(loginService, { email, password });
  if (err) {
    return reject(err);
  }

  const { token } = res.data;
  localStorage.setItem("auth_token", token);

  setAuthHeaderToken();
  yield put(userDetailRequest({ resolve: () => {}, reject: () => {} }));
  return resolve();
}

export function* ForgotPasswordRequestFlow(action) {
  const { email, resolve, reject } = action.payload;

  const { err, res } = yield call(forgotPasswordRequest, { email });
  if (err) {
    return reject(err);
  }

  localStorage.setItem("forgotPasswordEmail", email);
  resolve();
}

export function* VerifyForgotPasswordCodeFlow(action) {
  const { resolve, reject, code, email } = action.payload;

  const { err, res } = yield call(forgotPasswordConfirm, { email, code });
  if (err) {
    return reject(err);
  }
  localStorage.setItem("otp", code);
  resolve();
}

export function* ChangePasswordFlow(action) {
  const { resolve, reject, reqBody } = action.payload;

  const { err, res } = yield call(changePassword, reqBody);
  if (err) {
    return reject(err);
  }
  localStorage.clear();
  resolve();
}

function* RegisterUserFlow(action) {
  const { resolve, reject, data } = action.payload;

  const { err, res } = yield call(registerService, data);
  if (err) {
    return reject(err);
  }

  localStorage.setItem("hb-reg-email", data.email);
  resolve();
}

function* RegisterVerifyFlow(action) {
  const { resolve, reject, data } = action.payload;
  const { err, res } = yield call(verifyEmailService, data);
  if (err) {
    reject(err);
    return;
  }
  const { token } = res.data;
  localStorage.setItem("auth_token", token);

  setAuthHeaderToken();
  yield put(userDetailRequest({ resolve: () => {}, reject: () => {} }));

  resolve(res.data);
}

function* ResendRegVerifyCodeFlow(action) {
  const { resolve, reject, data } = action.payload;
  const { err, res } = yield call(resendRegVerifyCodeService, data);
  if (err) {
    reject();
    return;
  }

  resolve();
}

function* UpdateUserSettingFlow(action) {
  const { resolve, reject, data } = action.payload;
  const { err, res } = yield call(updateUserSettings, data);
  if (err) {
    return reject();
  }

  yield put(setUser(res.data));
  resolve();
}

function* UpdateUserPasswordFlow(action) {
  const { resolve, reject, data } = action.payload;
  const { err, res } = yield call(updateUserPassword, data);
  if (err) {
    return reject(err);
  }

  resolve();
}

function* RegisterVendorUserFlow(action) {
  const { resolve, reject, data } = action.payload;

  const { err, res } = yield call(registerVendorService, data);
  if (err) {
    return reject(err);
  }

  localStorage.setItem("hb-reg-email", data.email);
  resolve();
}

// function* RegisterVendorVerifyFlow(action) {
//   const { resolve, reject, data } = action.payload;
//   const { err, res } = yield call(verifyEmailService, data);
//   if (err) {
//     reject(err);
//     return;
//   }
//   const { token } = res.data;
//   localStorage.setItem("auth_token", token);

//   setAuthHeaderToken();
//   yield put(userDetailRequest({ resolve: () => {}, reject: () => {} }));

//   resolve(res.data);
// }

// function* ResendRegVendorVerifyCodeFlow(action) {
//   const { resolve, reject, data } = action.payload;
//   const { err, res } = yield call(resendRegVerifyCodeService, data);
//   if (err) {
//     reject();
//     return;
//   }

//   resolve();
// }

function* ActionWatcher() {
  yield takeLatest(AUTH_.LOGIN_REQUEST, LoginFlow);
  yield takeLatest(AUTH_.FORGOT_PASSWORD_REQUEST, ForgotPasswordRequestFlow);
  yield takeLatest(
    AUTH_.VERIFY_FORGOT_PASSWORD_CODE,
    VerifyForgotPasswordCodeFlow
  );
  yield takeLatest(AUTH_.CHANGE_PASSWORD, ChangePasswordFlow);

  //Registration
  yield takeLatest(AUTH_.REGISTER_REQUEST, RegisterUserFlow);
  yield takeLatest(AUTH_.VERIFY_REGISTER_REQUEST, RegisterVerifyFlow);
  yield takeLatest(AUTH_.RESEND_REGISTER_VERIFY_CODE, ResendRegVerifyCodeFlow);

  yield takeLatest(AUTH_.UPADATE_USER_SETTINGS_REQUEST, UpdateUserSettingFlow);
  yield takeLatest(AUTH_.UPDATE_USER_PASSWORD_REQUEST, UpdateUserPasswordFlow);

  //Registration
  yield takeLatest(AUTH_.REGISTER_VENDOR_REQUEST, RegisterVendorUserFlow);
  // yield takeLatest(AUTH_.VERIFY_REGISTER_VENDOR_REQUEST, RegisterVendorVerifyFlow);
  // yield takeLatest(AUTH_.RESEND_REGISTER_VENDOR_VERIFY_CODE, ResendRegVendorVerifyCodeFlow);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
