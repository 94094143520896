import { takeLatest, all, call, put } from "redux-saga/effects";

import { userDetailService, userDashboardService } from "services/userServices";
import * as USER_ from "store/constants/user";
import { setUser } from "store/actions/user";
import { GetFavoriteModels } from "store/actions/models";

function* MeFlow(action) {
  const { resolve, reject } = action.payload;

  // temporary
  //const { res, err } = yield call(userDetailService);
  const { res, err } = yield call(userDetailService);
  if (err) {
    return reject();
  }

  yield put(setUser(res.data));
  yield put(GetFavoriteModels());
  return resolve(res.data);
}

function* ActionWatcher() {
  yield takeLatest(USER_.ME_REQUEST, MeFlow);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
