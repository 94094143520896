import API from "./";

export const loginService = (reqBody) =>
  API.post("/auth/token/login/", reqBody);
export const logoutService = () => API.get("/api/auth/logout");

/**
 * reqBody:{
 *  email,
 * }
 */
export const forgotPasswordRequest = (reqBody) =>
  API.post("/auth/password/reset/", reqBody);

/**
 * reqBody: {
 *  email,
 *  code
 * }
 */
export const forgotPasswordConfirm = (reqBody) =>
  API.post("/auth/password/reset/confirm/", reqBody);

/**
 * reqBody : {
 *  email,
 *  code,
 *  password1,
 *  password2
 * }
 */
export const changePassword = (reqBody) =>
  API.post("/auth/password/reset/change/", reqBody);

export const getUserSettings = () => API.get("/auth/account-settings/");
export const updateUserSettings = (reqBody) =>
  API.patch("/auth/account-settings/", reqBody);
export const updateUserPassword = (reqBody) =>
  API.patch("/auth/change-password/", reqBody);

/*
 * HOME BUYER
 */

export const registerService = (reqBody) =>
  API.post("/auth/register/", reqBody);

export const resendRegVerifyCodeService = (reqBody) =>
  API.post("/auth/register/confirm/resend/", reqBody);

export const verifyEmailService = (reqBody) =>
  API.post("/auth/register/confirm/", reqBody);

  /*
   * VENDOR PARTNER
   */
  
  export const registerVendorService = (reqBody) =>
    API.post("/vendors/register/", reqBody);
  
  // export const resendRegVerifyCodeService = (reqBody) =>
  //   API.post("/auth/register/confirm/resend/", reqBody);
  
  // export const verifyEmailService = (reqBody) =>
  //   API.post("/auth/register/confirm/", reqBody);
