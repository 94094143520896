import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import { CreateInfoNotification } from "helpers/notification";
import { GetImageUrl } from "helpers/aux";
import { UploadSnapshot } from "services/TourServices";

import SendSMSModal from "components/modals/SendSMSModal";

const SnapshotModal = (props) => {
  const { screenshot, setScreenshot, modelName, roomName } = props;

  const [showSMSModal, setShowSMSModal] = useState(false);
  const [uploadedSnapshot, setUploadedSnapshot] = useState("");
  const [downloadText, setDownloadText] = useState("Download");

  const uploadSnapshot = async (screenshot) => {
    const {
      res: {
        data: { image },
      },
      err,
    } = await UploadSnapshot(screenshot);

    setUploadedSnapshot(GetImageUrl(image));
  };

  useEffect(() => {
    if (screenshot) {
      uploadSnapshot(screenshot);
    }
  }, [screenshot]);

  const shareUrl = "https://3dconsumer.com" + uploadedSnapshot;

  const ShareSMSHandler = (phone_number) => {
    console.log(phone_number);
    setShowSMSModal(false);
    alert("This part is under construction");
  };

  const DownloadScreenshot = (e) => {
    e.preventDefault();
    var today = new Date();
    let link = document.createElement('a');
    link.download = modelName + '-' + 
      roomName + '-' + 
      today.toISOString().substring(0, 10) + 
      '.jpeg';
    link.href = screenshot;
    document.body.appendChild(link);
    link.click();
    setDownloadText("Downloading...");
    setTimeout(() => 
    setDownloadText("Download"),
    1000);
  };

  return (
    <>
      <SendSMSModal
        isOpen={showSMSModal}
        setModalState={setShowSMSModal}
        submitHandler={ShareSMSHandler}
      />
      <Modal
        show={screenshot !== ""}
        centered={true}
        onHide={() => setScreenshot("")}
        className="share-modal"
      >
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            onClick={() => setScreenshot("")}
          ></button>
        </Modal.Header>
        <Modal.Body className="form-modal px-5 pb-5">
          <img src={screenshot} className="py-3 w-100 mb-3" alt="" />
          <div className="d-flex justify-content-center align-items-center mb-3">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(window.location.href);
                CreateInfoNotification("Copied URL to clipboard");
              }}
            >
              <i className="far fa-copy me-1" aria-hidden="true"></i> Copy the
              Tour's Link
            </a>
          </div>
          <h1 className="mb-4">Share to:</h1>
          <div className="row row-cols-2 row-cols-md-3 align-content-end ">
            <div className="col mb-3">
              <FacebookShareButton url={shareUrl}>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <i
                    className="fab fa-facebook-square me-2"
                    aria-hidden="true"
                  ></i>{" "}
                  Facebook
                </a>
              </FacebookShareButton>
            </div>
            <div className="col mb-3">
              <EmailShareButton url={shareUrl}>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <i className="far fa-envelope me-2" aria-hidden="true"></i>{" "}
                  Email
                </a>
              </EmailShareButton>
            </div>
            <div className="col mb-3">
              <WhatsappShareButton url={shareUrl}>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <i className="fab fa-whatsapp me-2" aria-hidden="true"></i>{" "}
                  WhatsApp
                </a>
              </WhatsappShareButton>
            </div>
            <div className="col mb-3">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowSMSModal(true);
                }}
              >
                <i className="fas fa-sms me-2" aria-hidden="true"></i> SMS
              </a>
            </div>
            <div className="col mb-3">
              <TwitterShareButton url={shareUrl}>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <i className="fab fa-twitter me-2" aria-hidden="true"></i>{" "}
                  Twitter
                </a>
              </TwitterShareButton>
            </div>
            <div className="col mb-3">
              <a href="#" onClick={(e) => DownloadScreenshot(e)}>
                <i className="fas fa-download me-2" aria-hidden="true"></i>{" "}
                {downloadText}
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SnapshotModal;
