export const CREATE_PROJECT_REQUEST = `CREATE_PROJECT_REQUEST`;
export const CREATE_PROJECT_SUCCESS = `CREATE_PROJECT_SUCCESS`;

export const GET_PROJECTS = "GET_PROJECTS";
export const SET_PROJECTS = "SET_PROJECTS";

export const GET_ACTIVE_PROJECT = "GET_ACTIVE_PROJECT";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED";
