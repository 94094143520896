import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ForgotPasswordRequest } from "store/actions/auth";

const InputEmail = (props) => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { setShowLoadingOverlay } = props;

  const submitForm = (e) => {
    e.preventDefault();

    setSubmitting(true);
    setShowLoadingOverlay(true);
    return new Promise((resolve, reject) => {
      dispatch(ForgotPasswordRequest({ resolve, reject, email }));
    })
      .then(() => {
        props.history.push("/forgot-password/verify");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
        setShowLoadingOverlay(false);
      });
  };

  return (
    <div
      className="box-transparent p-5"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h1 className="form-title mb-5">Forgot Password</h1>
      <p className="p-form mb-5">Please enter your email below.</p>

      <form onSubmit={submitForm}>
        <div className="row gap-4 mb-5">
          <div className="col-12">
            <label htmlFor="" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id=""
              placeholder="Enter Email Address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
        </div>
        <div className="d-grid mb-4">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="text-center form-foot text-uppercase">
        <span>
          Don't have an account?
          <Link to="/register" className="link fw-bold ms-1">
            {" "}
            Sign up Here
          </Link>
        </span>
        <a
          href="#"
          className="link ms-1 d-block mt-2"
          onClick={(e) => {
            e.preventDefault();
            props.history.push("/listings?openLoginModal=true");
          }}
        >
          Back to login
        </a>
      </div>
    </div>
  );
};

export default withRouter(InputEmail);
