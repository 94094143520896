/*
 * ModelImageModal
 *  - THIS COMPONENT IS USED IN HOMEBUYER SIDE
 *  - This component is used for showing the selected image of the model
 *  - Images can be zoomed on hover
 */

import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { GetImageUrl } from "helpers/aux";

const ModelImageModal = (props) => {
  const {
    isOpen,
    setModalState,
    // activeModel: { name },
    modelName,
    modelImage,
  } = props;

  return (
    // css copied to floor plan modal
    <Modal show={isOpen} centered className="floor-plan model-image" size="xl">
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-4 pb-5">
        <div className="container-fluid">
          <span className="me-3">{modelImage?.name || modelName || "No image name"}</span>

            <div className="row">
              <div className="col my-4">
                <img
                  src={GetImageUrl(modelImage?.image)}
                  className="img-fluid"
                ></img>
              </div>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeModel: state.models.active,
});

export default connect(mapStateToProps)(ModelImageModal);
