/*
 * Auxilliary functions
 */

import { getModelsService } from "services/modelServices";
import { getProjectService } from "services/projectServices";

export const encodeImageTo64 = async (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      resolve({ file_name: file.name, encodedImage: reader.result });
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const CreateOption = (value) => ({
  value,
  label: value,
});

export const GetOptionsValue = (selectedOptions = []) => {
  return selectedOptions.map((option) => ({
    ...option,
    name: option.value,
  }));
};

export const imageUrlToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target.result;
        resolve(res);
      };
      var file = this.response;
      reader.readAsDataURL(file);
    };
    xhr.send();
  });
};

export const GetUploadedFiles = (files = []) => {
  let uploadedFiles = files.map(async (file) => {
    let img = `http://localhost:8000${file.file}`;
    let encodedImage = await imageUrlToBase64(img);
    let file_name = img.split("/");
    file_name = file_name[file_name.length - 1];
    return { ...file, file_name, encodedImage };
  });
  return uploadedFiles;
};

export const GetImageUrl = (image) => {
  return process.env.NODE_ENV === "development"
    ? `http://localhost:8000${image}`
    : image;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function isDataURL(s) {
  return !!s.match(isDataURL.regex);
}
isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

export const GetModelDetails = async (modelId, communityId) => {
  let { res: models, err: modelsErr } = await getModelsService(communityId);

  if (modelsErr) {
    console.log(modelsErr);
    return;
  }

  const model = models.data.find((x) => x.id == modelId);

  return model;
};
