import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import Swiper, {Autoplay, Navigation, Pagination} from "swiper";

const Vendor = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {

        Swiper.use([Navigation, Pagination, Autoplay]);
        new Swiper(".mySwiper", {
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true,
            },
            autoplay: {
                delay: 3000,
            },
        });

    }, []);

    return (<>
        {/* <!-- Home Hero --> */}
        <div className="vh-100 main-hero"
             style={{backgroundImage: "url('img/bg-hero-partner-compressed.png')", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
            <div className="container h-100 text-white box-container d-flex align-items-center">
                <div className="mt-5 mt-md-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h1 data-aos="fade" data-aos-duration="1000" data-aos-delay="200"
                                    className="mb-2 my-md-4 aos-init aos-animate">Showcase Your Products In 3D</h1>
                                <h2 className="mb-2 my-md-4 fw-normal aos-init aos-animate" data-aos="fade"
                                    data-aos-duration="1000" data-aos-delay="200">Looks like the real thing!</h2>
                                <h2 className="mb-2 my-md-4 fw-normal aos-init aos-animate" data-aos="fade"
                                    data-aos-duration="1000" data-aos-delay="200">Easy to use!</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row my-1 my-md-5 long-text aos-init aos-animate" data-aos="fade"
                         data-aos-duration="1000" data-aos-delay="300">
                        <div className="col-12 text-center">
                            <p className="p1 fw-bold mb-2 my-md-4">Created For:</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <p className="fw-bold fs-24">Preconstruction Real Estate Sales</p>
                            <p className="fs-22">Buyers sell themselves as they mix and match your available finishes
                                until they discover what they like!</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <p className="fw-bold fs-24">Home Remodeling Showrooms</p>
                            <p className="fs-22">Shoppers are more likely to make a purchase on their first visit!</p>
                        </div>
                        <div className="col-12 text-center">
                            <a href="/lets-talk"
                               className="btn btn-lg btn-primary me-lg-2 d-block d-lg-inline-block text-center mt-lg-5 mt-3">Let's
                                Talk</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- / Home Hero --> */}
        {/* <!-- How It Works --> */}
        <section className="vid">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <h1 className="mb-5 text-center text-white aos-init aos-animate" data-aos="fade"
                            data-aos-duration="1000" data-aos-delay="200">
                            A Unique and Exciting Experience!
                        </h1>
                        <video className="w-100" controls autoPlay muted playsinline loop controls>
                            <source src="/video/how-to-video-vendor.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- / How It Works --> */}
        {/* <!-- Virtual --> */}
        <section>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className="mb-5 text-center aos-init aos-animate" data-aos="fade"
                            data-aos-duration="1000" data-aos-delay="500">
                            Why 3D Consumer Works
                        </h1>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-3 g-lg-5">
                    <div className="col mb-3 mb-lg-0">
                        <div className="step-bg p-3 p-lg-5 h-100 aos-init aos-animate" data-aos="fade"
                             data-aos-duration="1000" data-aos-delay="200">
                            <div className="d-flex steps align-items-center">
                                <div className="num">
                                    <h2>1</h2>
                                </div>
                                <h2>Eliminate The Guesswork</h2>
                            </div>
                            <p>
                                Shoppers can mix and match your products in Virtual Reality and INSTANTLY see the end results.
                            </p>
                        </div>
                    </div>
                    <div className="col mb-3 mb-lg-0">
                        <div className="step-bg p-3 p-lg-5 h-100 aos-init aos-animate" data-aos="fade"
                             data-aos-duration="1000" data-aos-delay="400">
                            <div className="d-flex steps align-items-center">
                                <div className="num">
                                    <h2>2</h2>
                                </div>
                                <h2>Increase Sales in Less Time</h2>
                            </div>
                            <p>
                                Selections are made fast and with confidence.
                            </p>
                        </div>
                    </div>
                    <div className="col mb-3 mb-lg-0">
                        <div className="step-bg p-3 p-lg-5 h-100 aos-init aos-animate" data-aos="fade"
                             data-aos-duration="1000" data-aos-delay="600">
                            <div className="d-flex steps align-items-center">
                                <div className="num">
                                    <h2>3</h2>
                                </div>
                                <h2>Beat the Competition</h2>
                            </div>
                            <p>
                                No need to keep shopping around once users find the right combination of your products.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        {/* <!-- / Virtual --> */}
        <section className="visual">
            <div className="grad-ceil"></div>
            <div className="grad-floor"></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <h3 className="text-center fw-bold mb-4 aos-init aos-animate" data-aos="fade-in"
                            data-aos-duration="1000" data-aos-delay="0">Use 3D to give yourself an advantage over your
                            competitors</h3>
                        <h1 className="text-center fw-bold aos-init aos-animate" data-aos="fade-in"
                            data-aos-duration="1000" data-aos-delay="200">Everyone Needs a Visual</h1>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="400"
                             className="aos-init aos-animate">
                            <img src="/img/image-asset-01-B.png" className="img-fluid p-5"/>
                        </div>

                    </div>
                    <div className="col-md-5">
                        <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="600"
                             className="aos-init aos-animate">
                            <img src="/img/image-asset-02-B.png" className="img-fluid p-5"/>
                        </div>

                    </div>
                </div>

            </div>
        </section>
        {/* <!-- Designer --> */}
        <section>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 ps-md-5">
                        <h1 className="mb-4 aos-init aos-animate" data-aos="fade-in" data-aos-duration="1000"
                            data-aos-delay="200">
                            <img src="/img/ico-dollar.svg" className="me-2"/>
                            Increased Profits
                        </h1>
                        <p className="mb-5 mb-md-0 aos-init aos-animate" data-aos="fade-in" data-aos-duration="1000"
                           data-aos-delay="400">
                            Confident shoppers are more likely to make a purchase.
                        </p>
                    </div>
                    <div className="col-md-6 order-md-first">
                        <img src="/img/2.png" className="img-fluid aos-init aos-animate" data-aos="fade"
                             data-aos-duration="1000" data-aos-delay="600"/>
                    </div>
                </div>

            </div>
        </section>
        {/* <!-- / Designer --> */}
        {/* <!-- Callout --> */}
        <section
            style={{
                backgroundImage: "url('/img/callout-bg.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }}>
            <div className="container">
                <div className="row align-items-center justify-content-center text-center text-white">
                    <div className="col">
                        <h1 className="mb-3 aos-init aos-animate" data-aos="fade" data-aos-duration="1000"
                            data-aos-delay="500">
                            Get started with 3D Consumer today!
                        </h1>
                        <p className="mb-5 aos-init aos-animate" data-aos="fade" data-aos-duration="1000"
                           style={{fontWeight: "bolder", fontSize: 18}}
                           data-aos-delay="700">
                            Give shoppers the confidence and peace of mind to move forward with their purchase knowing
                            they have made all the right decisions.
                        </p>
                        <div data-aos="fade-up" data-aos-duration="1400" data-aos-delay="900"
                             className="aos-init aos-animate">
                            <a href="/lets-talk" className="d-block d-md-inline-block btn btn-primary">Let's
                                Talk</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- / Callout --> */}

    </>);
};

export default Vendor;
