import { useEffect, useState } from "react";
import axios from "axios";

import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const CommunityMap = (props) => {
  const query = new URLSearchParams(props.location.search);
  const address = query.get("address");

  const [communityLocation, setCommunityLocation] = useState("");

  useEffect(() => {
    const getLocation = async () => {
      const geocode = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_MAP_API_KEY}`
      );

      const {
        geometry: { location },
      } = geocode.data.results[0];

      setCommunityLocation(location);
    };
    getLocation();
  }, []);

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  if (!communityLocation || !address) return null;

  return (
    <Map
      google={props.google}
      zoom={15}
      style={mapStyles}
      initialCenter={communityLocation}
    >
      <Marker position={communityLocation} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
})(CommunityMap);
