import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import HomebuyerNavbar from "components/navbar/homebuyerNavbar";
import ItemStyleSlider from "components/slider/ItemStyleSlider";
import TourPresetSlider from "components/slider/TourPresetSlider";
import {
  RequestShowingModal,
  SaveTourModal,
  SnapshotModal,
  CostModal,
} from "components/modals/Tour";

import { UpdateFavoriteModel, FavoriteModel } from "store/actions/models";
import { SetShowLoginModal } from "store/actions/auth";

import { GetFavoriteModel } from "services/modelServices";
import { GetModelDetails } from "helpers/aux";
import { CreateSuccessNotification } from "helpers/notification";
import JSONfromSuperAdmin from "mock-data/JSONFromSuperAdmin";

const initialState = {
  tourObj: "",
  rooms: [{ name: "", items: [{ name: "", style: "" }] }],
  selectedRoom: "",
  activeItem: { styles: [], name: "" },
  isCustomizing: false,
  isShowPreset: false,
  presets: { imagePreset: "", name: "" },
  selectedPreset: "",
  screenshot: "",
  modelName: "",
  showRequestShowingModal: false,
  showSaveTourModal: false,
  showCostModal: false,
  saved: false,
};

class TourImage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.prevScene = "";
    this.initialized = false;
    this.authenticated = false;
    this.favoriteModel = null;
    this.modelDetail = null;
  }

  _setDefaultScene = (obj) => {
    let tourObj = { ...obj };
    let rooms = [...tourObj.rooms];

    this.setState({
      tourObj,
      rooms,
      selectedRoom: rooms[0].name, // Sets the first room to be the active room
      activeItem: {
        ...initialState.activeItem,
        position: rooms[0].initialPosition || { pitch: 0, yaw: 180 },
      },
      presets : rooms[0].presets,
      selectedPreset: rooms[0].defaultPreset,
      modelName: tourObj.name,
    });
  };

  _getScene = () => {
    const { selectedRoom, rooms, tourObj, selectedPreset } = this.state;
    const room = rooms.find((room) => room.name === selectedRoom);

    const { combinations, defaultScene } = tourObj.rooms.find(
      (room) => room.name === selectedRoom
    );

    // Re-structures `active room items' structure` to match `room combination items' structure`
    // `style` field ->  finds the selected style and returns it's name
    // const activeRoomItems = [...room.items].map((item) => ({
    //   name: item.name,
    //   style: item.styles.find((style) => style.selected).name,
    // }));
    let activeRoomItems = [...room.items.defaults].map((item) => ({
      name: item.name,
      style: item.styles.find((style) => style.selected).name,
    }));

    // // Add Preset Items
    // let customRoomItems = [...room.items.custom].map((item) => {
    //   if (item.presetName === selectedPreset) {
    //     return [item.items].map((hotspot) => ({
    //       name: hotspot.name,
    //       style: hotspot.styles.find((style) => style.selected).name,
    //     }));
    //   }
    // });

    // activeRoomItems = [...activeRoomItems, ...customRoomItems];

    let combinationMatch = combinations.find((combination) => {
      // let item1 = JSON.stringify(combination.items);
      // let item2 = JSON.stringify(activeRoomItems);

      let item1 = combination.name;
      let item2 = selectedPreset;

      return item1 === item2;
    });
    // return combinationMatch?.image ?  GetImageUrl(combinationMatch?.image) : defaultScene;

    return combinationMatch?.image || defaultScene;
  };

  _getRoomItems = () => {
    const { selectedRoom, tourObj, selectedPreset } = this.state;
    if (!tourObj) return [];

    const room = tourObj.rooms.find((room) => room.name === selectedRoom);

    // return room?.items?.defaults || [];

    let activeRoomItems = [...room.items.defaults].map((item) => ({
      name: item.name,
      style: item.styles.find((style) => style.selected).name,
    }));

    // // Add Preset Items
    // let customRoomItems = [...room.items.custom].map((item) => {
    //   if (item.presetName === selectedPreset) {
    //     return [item.items].map((hotspot) => ({
    //       name: hotspot.name,
    //       style: hotspot.styles.find((style) => style.selected).name,
    //     }));
    //   }
    // });

    // activeRoomItems = [...activeRoomItems, ...customRoomItems];

    return activeRoomItems || [];
  };

  _updateRoomStyle = (itemName, style) => {
    const { selectedRoom } = this.state;
    const rooms = [...this.state.rooms];

    // Get `Active Room`
    const room = rooms.find((room) => room.name === selectedRoom);

    const styleName = style.name;

    //Modify Items of the selected room base on the itemName
    const items = [...room.items.defaults].map((item) => {
      if (item.name === itemName) {
        return {
          ...item,
          //modify styles array of the item and set the selected to true if it matches styleName
          styles: item.styles.map((style) => {
            if (style.name === styleName) {
              return {
                ...style,
                selected: true,
              };
            }
            return { ...style, selected: false };
          }),
        };
      }
      return item;
    });

    room.items.defaults = items;

    this.setState({
      rooms,
      activeItem: {
        position: rooms[0].initialPosition || { pitch: 0, yaw: 180 },
      },
      presets: rooms.presets,
    });
  };

  _getPresetImages = (itemName, style) => {
    const { selectedRoom } = this.state;
    const rooms = [...this.state.rooms];
    // Get `Active Room`
    const room = rooms.find((room) => room.name === selectedRoom);
    const styleName = style.name;

    this.setState({
      rooms,
      // activeItem: {
      //   position: rooms[0].initialPosition || { pitch: 0, yaw: 0 },
      // },
      // presets: rooms.presets,
      selectedPreset: styleName,
      isShowPreset: false,
      isCustomizing: false,
    });
  };

  _closeSlider = () => {
    this.setState({
      isShowPreset: false,
      isCustomizing: false,
    });
  };

  _setActiveHotspotItem = (item) => {
    const { activeItem } = this.state;
    if (JSON.stringify(item) === JSON.stringify(activeItem)) {
      this.setState({
        activeItem: initialState.activeItem,
      });
      return;
    }

    this.setState({
      activeItem: item,
    });
  };

  _getHotSpots = () => {
    const { selectedRoom, tourObj, selectedPreset } = this.state;
    const rooms = [...tourObj.rooms];
    const room = rooms.find((room) => room.name === selectedRoom);

    let hotSpots = [...room.items.defaults].map((item) => {
      return {
        pitch: item.position.pitch,
        yaw: item.position.yaw,
        type: "info",
        clickHandlerFunc: () => this._setActiveHotspotItem(item),
        cssClass: "btn-hotspot",
      };
    });

    // Add Preset Items
    let customHotspot = [];
    [...room.items.custom].map((item) => {
      if (item.presetName === selectedPreset) {
        customHotspot = item.items.map((hotspot) => (
          {
            pitch: hotspot.position.pitch,
            yaw: hotspot.position.yaw,
            type: "info",
            clickHandlerFunc: () => this._setActiveHotspotItem(hotspot),
            cssClass: "btn-hotspot",
          }
        ));
      }
    });

    // Check if more custom hotspots
    hotSpots = [...hotSpots, ...customHotspot];

    return hotSpots;
  };

  _customizeBtnHandler = (e) => {
    e.preventDefault();
    this.setState({
      isCustomizing: !this.state.isCustomizing,
      activeItem: initialState.activeItem,
      isShowPreset: false,
    });
  };

  _showPresetBtnHandler = (e) => {
    e.preventDefault();
    this.setState({
      isShowPreset: !this.state.isShowPreset,
      // activeItem: initialState.activeItem,
      isCustomizing: false,
    });
  };

  /* TOUR CONTROLS*/

  _takeScreenshot = (e) => {
    e.preventDefault();
    const { rooms, selectedRoom, tourObj } = this.state;
    const room = rooms.find((room) => room.name === selectedRoom);

    let screenshot = null; //replace with default image
    if (room.isTour) {
      // const screenshot = this.viewer
      screenshot = this.viewer
        .getRenderer()
        .render(
          (this.viewer.getPitch() / 180) * Math.PI,
          (this.viewer.getYaw() / 180) * Math.PI,
          (this.viewer.getHfov() / 180) * Math.PI,
          { returnImage: true }
        );
    } else {
      const { defaultScene } = tourObj.rooms.find(
        (room) => room.name === selectedRoom
      );
      screenshot = defaultScene;
    }
  
    this.setState({
      screenshot,
    });
  };

  _exitHandler = (e) => {
    e.preventDefault();

    const { saved } = this.state;

    if (!saved) {
      this.setState({
        showSaveTourModal: true,
      });

      return;
    }

    if (this.modelDetail) {
      const { id, project } = this.modelDetail;
      this.props.history.push(`/listings/${project}/model-detail/${id}`);
    }

    if (this.favoriteModel) {
      this.props.history.push(`/saved-homes/${this.favoriteModel.id}`);
    }
  };

  _heartHandler = (e) => {
    e.preventDefault();

    if (!this.authenticated) {
      this.props.SetShowLoginModal(true);
      return;
    }

    this.setState({
      showSaveTourModal: true,
    });
  };

  _priceHandler = (e) => {
    e.preventDefault();
    this.setState({
      showCostModal: true,
    });
  };

  _requestShowingHandler = (e) => {
    e.preventDefault();

    if (!this.authenticated) {
      this.props.SetShowLoginModal(true);
      return;
    }
    this.setState({
      showRequestShowingModal: true,
    });
  };

  /* TOUR CONTROLS END */

  saveTourHandler = (layout_name) => {
    const tourObj = { ...this.state.tourObj };
    const rooms = this.state.rooms;
    const { favoriteModels } = this.props;

    const { FavoriteModel, UpdateFavoriteModel } = this.props;
    if (this.modelDetail) {
      const { id } = this.modelDetail;
      let favoriteModelMatch = favoriteModels.find((x) => x.model.id === id);

      if (!favoriteModelMatch) {
        FavoriteModel({ id, layout_name, unity_body: { ...tourObj, rooms } });
      } else {
        UpdateFavoriteModel({
          favoriteModelId: favoriteModelMatch.id,
          data: { layout_name, tour_data: { ...tourObj, rooms } },
        });
      }
    }

    if (this.favoriteModel) {
      UpdateFavoriteModel({
        favoriteModelId: this.favoriteModel.id,
        data: { layout_name, tour_data: { ...tourObj, rooms } },
      });
    }

    this.setState({
      showSaveTourModal: false,
      saved: true,
    });

    CreateSuccessNotification("Successfully saved ");
  };

  componentDidUpdate(prevProps, prevState) {
    const authenticated = Object.entries(this.props.user).length;
    this.authenticated = authenticated;
    const { tourObj } = this.state;
    if (tourObj) {
      let scene = this._getScene();
      const hotSpots = this._getHotSpots();
      const {
        activeItem: { position },
        isCustomizing,
        selectedRoom,
      } = this.state;

      let panoramaContainer = document.getElementById("panorama");

      if (scene !== this.prevScene) {
        panoramaContainer.innerHTML = ""; // remove the content of the element first to render new image

        if (selectedRoom !== 'Kitchen') {
          panoramaContainer.classList.remove("pnlm-container");
          let elem = document.createElement("img");
          elem.setAttribute("src", scene);
          elem.setAttribute("height", "100%");
          elem.setAttribute("width", "100%");
          document.getElementById("panorama").appendChild(elem);
        } else {
          this.viewer = window.pannellum.viewer("panorama", {
            type: "equirectangular",
            panorama: scene,
            autoLoad: true,
            hotSpots,
            compass: false,
            pitch: position?.pitch || 0,
            yaw: position?.yaw || 180,
            showControls: false,
          });
        }

        this.setState({
          activeItem: { ...initialState.activeItem, position },
          isCustomizing: false,
          isShowPreset: false,
        });

        this.prevScene = scene;
      }

      let hotspotElements = document.querySelectorAll(".pnlm-hotspot-base");

      if (isCustomizing) {
        [].forEach.call(hotspotElements, function (el) {
          el.style.display = "block";
        });
      } else {
        [].forEach.call(hotspotElements, function (el) {
          el.style.display = "none";
        });
      }

      if (!isCustomizing) {
        this.viewer.on("load", () => {
          let hotspotElements = document.querySelectorAll(".pnlm-hotspot-base");
          [].forEach.call(hotspotElements, function (el) {
            el.style.display = "none";
          });
          return;
        });
      }
    }
  }

  async componentDidMount() {
    const search = this.props.location.search;
    const queryParams = new URLSearchParams(search);

    /*
     * TODO
     * GET `unity_body` from model
     * OR
     * GET `tour_data` from favorite model
     *
     * - DONE
     */

    this.queryParams = null;
    const modelId = queryParams.get("modelId");
    const communityId = queryParams.get("communityId");

    const favoriteModelId = queryParams.get("favoriteModelId");

    if (modelId && communityId) {
      const modelDetail = await GetModelDetails(modelId, communityId);
      this.modelDetail = modelDetail;
      const { unity_body } = modelDetail;
      if (Object.entries(unity_body).length) {
        this._setDefaultScene(unity_body);
        return;
      }
    }

    if (favoriteModelId) {
      const { res, err } = await GetFavoriteModel(favoriteModelId);

      this.favoriteModel = res.data;
      const { tour_data } = res.data;
      //Check if tour_data has value
      if (Object.entries(tour_data).length) {
        this._setDefaultScene(tour_data);
        return;
      }
    }

    // Sets temporary scene
    this._setDefaultScene(JSONfromSuperAdmin);
  }

  render() {
    const {
      activeItem,
      isCustomizing,
      isShowPreset,
      presets,
      screenshot,
      rooms,
      showRequestShowingModal,
      showSaveTourModal,
      selectedRoom,
      showCostModal,
    } = this.state;
    const roomItems = this._getRoomItems();
    const styles = activeItem.styles;
    const itemName = activeItem.name;

    let modelName = this.modelDetail?.name || this.favoriteModel?.model?.name;

    return (
      <>
        {/* <CostModal
          isOpen={showCostModal}
          setModalState={(modalState) =>
            this.setState({ showCostModal: modalState })
          }
          roomName={selectedRoom}
          roomItems={roomItems}
        /> */}
        <SaveTourModal
          isOpen={showSaveTourModal}
          setModalState={(modalState) =>
            this.setState({ showSaveTourModal: modalState })
          }
          SubmitFormHandler={this.saveTourHandler}
        />
        <RequestShowingModal
          isOpen={showRequestShowingModal}
          setModalState={(modalState) =>
            this.setState({ showRequestShowingModal: modalState })
          }
        />
        <SnapshotModal
          modelName={modelName}
          roomName={selectedRoom}
          screenshot={screenshot}
          setScreenshot={(value) => this.setState({ screenshot: value })}
        />
        <HomebuyerNavbar />
        <div className="row"
          style={{
            position: "absolute",
            paddingTop: "6rem",
            paddingLeft: "3rem",
            zIndex: 2,
            width: "620px",
          }}
        >
          <div className="col-6"
            // style={{
            //   position: "absolute",
            //   paddingTop: "6rem",
            //   paddingLeft: "3rem",
            //   zIndex: 2,
            // }}
          >
            <a
              href="#"
              className={classNames(
                "btn btn-transparent mb-2 d-block",
                "Kitchen" !== selectedRoom && "custom-hide",
                isCustomizing && "active"
              )}
              onClick={this._customizeBtnHandler}
            >
              {/* Customize */}
              View Materials
            </a>
          </div>
          <div className="col-6">
            <a
              href="#"
              className={classNames(
                "btn btn-transparent mb-2 d-block",
                "Kitchen" !== selectedRoom && "custom-hide",
                isShowPreset && "active"
              )}
              onClick={this._showPresetBtnHandler}
            >
              View More Designs
            </a>
          </div>
        </div>

        {/* Styles/Variations */}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 4,
            width: "100%",
            paddingBottom: "7rem",
          }}
        >
          {isCustomizing && (
            <TourPresetSlider
              styles={styles}
              // clickHandler={(style) => this._updateRoomStyle(itemName, style)}
              clickHandler={() => console.log()}
              closeSlider={() => this._closeSlider()}
            />
          )}
        </div>

        {/* Presets */}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 4,
            width: "100%",
            paddingBottom: "7rem",
          }}
        >
          {isShowPreset && (
            <TourPresetSlider
              styles={presets}
              clickHandler={(style) => this._getPresetImages(itemName, style)}
              closeSlider={() => this._closeSlider()}
              // clickHandler={() => console.log()}
              isPreset={true}
            />
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            paddingBottom: "3rem",
            zIndex: 6,
            paddingLeft: "3rem",
            paddingRight: "3rem",
            width: "100%",
          }}
        >
          <h3 className="me-3 text-light fw-bold mb-5">{modelName}</h3>
          <div className="row mx-0 tour tour-controls">
            <div className="col-6">
              <a href="#" className="me-3" onClick={this._takeScreenshot}>
                <img src="/img/snapshot.svg" className="p-1" />
              </a>
              <a href="#" className="me-3" onClick={this._heartHandler}>
                <img
                  src="img/heart.svg"
                  data-bs-toggle="modal"
                  data-bs-target="#save-layout"
                  className="p-1"
                  alt=""
                />
              </a>
              {/* <a
                href="#"
                className={classNames(
                  "me-3",
                  // "Kitchen" !== selectedRoom && "custom-hide"
                )}
                onClick={this._requestShowingHandler}
              >
                <img
                  src="img/price.svg"
                  data-bs-toggle="modal"
                  data-bs-target="#calculate-price"
                  className="p-1"
                  alt=""
                />
              </a> */}
              <a
                href="#"
                className="me-3"
                onClick={this._requestShowingHandler}
              >
                <img
                  src="img/request-showing.svg"
                  data-bs-toggle="modal"
                  data-bs-target="#request-showing"
                  className="p-1"
                  alt=""
                />
              </a>
            </div>
            <div className="col-6">
              <a
                href="#"
                className="me-3 float-end"
                onClick={this._exitHandler}
              >
                <img src="img/btn-exit.svg" className="p-1" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            zIndex: 2,
            position: "absolute",
            right: 0,
            display: "flex",
            flexDirection: "column",
            paddingTop: "6rem",
            paddingRight: "2rem",
          }}
        >
          {rooms.map((room, index) => {
            return (
              <a
                href="#"
                className={classNames(
                  "btn btn-transparent mb-2",
                  room.name === selectedRoom && "active",
                  (this.state.isCustomizing || this.state.isShowPreset) === true && "isDisabled"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    selectedRoom: room.name,
                  });
                }}
                key={index}
              >
                {room.name}
              </a>
            );
          })}
        </div>
        <div id="panorama" style={{ height: "100vh", width: "100%" }}></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  favoriteModels: state.models.favorites,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  UpdateFavoriteModel: (payload) => dispatch(UpdateFavoriteModel(payload)),
  FavoriteModel: (payload) => dispatch(FavoriteModel(payload)),
  SetShowLoginModal: (payload) => dispatch(SetShowLoginModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TourImage);

/*
 * Getting active scene
 *  - let activeRoom = Get `Active Room` by using selectedRoom
 *  - let activeRoomItems = activeRoom.items.map(item => ({ name: item.name, style: item.styles.find(style => style.selected).name }))
 *  - let scene =  activeRoom.combinations.find(combination => {
 *        if(JSON.stringify(combination.items) === JSON.stringify(activeRoomItems))
 *          return combination.image
 *        })
 *
 *  - return scene
 *
 */
