import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal";
import PhoneInput from "components/input/PhoneInput";

import {
  GetMeetingTypes,
  GetMeetingCountries,
  CreateRequestMeeting,
} from "services/projectServices";
import {
  CreateSuccessNotification,
  CreateErrorNotification,
} from "helpers/notification";

const RequestShowingModal = (props) => {
  const { isOpen, setModalState } = props;

  const [countryChoices, setCountryChoices] = useState([]);
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [selectedMeetingType, setSelectedMeetingType] = useState("");

  const { register, handleSubmit } = useForm();

  const SubmitForm = async (data) => {
    const { date, time } = data;
    const date_time = new Date(date + " " + time);
    const payload = { ...data, date_time };

    const { res, err } = await CreateRequestMeeting(payload);
    if (err) {
      CreateErrorNotification("Error occured");
      return;
    }

    CreateSuccessNotification("Successfully requested showing!");
    setModalState(false);
  };

  useEffect(() => {
    const initializeState = async () => {
      let { res: countries, err: countriesErr } = await GetMeetingCountries();
      let { res: types, err: typesErr } = await GetMeetingTypes();

      setCountryChoices(
        countries.data.map((item) => {
          return {
            value: item[0],
            text: item[1],
          };
        })
      );

      setMeetingTypes(
        types.data.map((item) => {
          return {
            value: item[0],
            text: item[1],
          };
        })
      );
    };

    initializeState();
  }, []);

  return (
    <Modal
      show={isOpen}
      centered
      className="delete-modal"
      onHide={() => setModalState(false)}
    >
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-5 pb-5">
        <h1 className="mb-4">Request Showing</h1>
        <form onSubmit={handleSubmit(SubmitForm)}>
          <label htmlFor="" className="form-label mt-4 mb-4">
            Community Info
          </label>
          <div className="row mb-4">
            <div className="row mb-4">
              <div className="col">
                <label htmlFor="" className="form-label">
                  Name
                </label>
                <input
                  required
                  name="community_name"
                  ref={register}
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Enter Name"
                />

                <label htmlFor="" className="form-label mt-4">
                  Model
                </label>
                <input
                  required
                  name="community_model"
                  ref={register}
                  type="text"
                  className="form-control"
                  placeholder="Enter Model"
                />
              </div>
            </div>
            <div className="row custom-calendar">
              <div className="col-lg-6 date-pick">
                <label htmlFor="" className="form-label">
                  Set Date &amp; Time
                </label>
                <input
                  ref={register}
                  name="date"
                  required
                  type="date"
                  className="form-control empty"
                  id="date"
                  placeholder="Enter Name"
                />
                {/*<i className="far fa-calendar-alt" aria-hidden="true"></i>*/}
              </div>
              <div className="col-lg-6 time-col">
                <label htmlFor="" className="form-label">
                  &nbsp;
                </label>

                <input
                  ref={register}
                  name="time"
                  required
                  type="time"
                  className="form-control time-pick empty"
                  id="time"
                />
                {/*<i className="far fa-clock" aria-hidden="true"></i>*/}
              </div>
            </div>
            <label htmlFor="" className="form-label mt-4">
              Notes
            </label>
            <textarea
              required
              ref={register}
              name="notes"
              className="form-control"
              rows="3"
              placeholder="Add Notes"
            ></textarea>
            <div className="row row-cols-2 row-cols-lg-3 custom-check px-lg-4 mt-4">
              {meetingTypes.map((meetingType) => {
                return (
                  <div className="col order-lg-1" key={meetingType.value}>
                    <div className="form-check">
                      <input
                        name="type"
                        ref={register}
                        className="form-check-input"
                        type="radio"
                        value={meetingType.value}
                        id={meetingType.value}
                        onChange={(e) => setSelectedMeetingType(e.target.value)}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor={meetingType.value}
                      >
                        {meetingType.text}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {selectedMeetingType &&
            ["BUYER", "BUYER_WITH_REALTOR", "OTHER"].includes(
              selectedMeetingType
            ) && (
              <>
                <label htmlFor="" className="form-label mt-4 mb-4">
                  Contact Info
                </label>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    First Name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    name="first_name"
                    ref={register}
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Last Name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="last_name"
                    ref={register}
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Email address
                  </label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    ref={register}
                    name="email"
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Phone Number
                  </label>
                  <PhoneInput
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone"
                    ref={register}
                    required
                    name="phone"
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Country
                  </label>
                  <select
                    ref={register}
                    id="country"
                    name="country"
                    className="form-select custom-select"
                    required
                  >
                    {countryChoices.map((country) => {
                      return (
                        <option value={country.value} key={country.value}>
                          {country.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </>
            )}

          {selectedMeetingType &&
            ["REALTOR", "REALTOR_WITH_BUYER", "BUYER_WITH_REALTOR"].includes(
              selectedMeetingType
            ) && (
              <>
                <label htmlFor="" className="form-label mt-4 mb-4">
                  Realtor Info
                </label>
                <div className="mb-4">
                  <label htmlFor="" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    ref={register}
                    required
                    name="realtor_first_name"
                  />
                  <label htmlFor="" className="form-label mt-4">
                    Last name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Address"
                    ref={register}
                    name="realtor_last_name"
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Company
                  </label>
                  <input
                    ref={register}
                    name="realtor_company"
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Name"
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    required
                    ref={register}
                    name="realtor_email"
                  />

                  <label htmlFor="" className="form-label mt-4">
                    Phone Number
                  </label>
                  <PhoneInput
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone"
                    ref={register}
                    name="realtor_phone"
                    required
                  />
                </div>
              </>
            )}
          <div className="d-grid mb-4">
            <button type="submit" className="btn btn-primary">
              Request Showing
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RequestShowingModal;
