/**
 * A.K.A. Communities
 */

import {
  CREATE_PROJECT_SUCCESS,
  GET_PROJECTS,
  SET_PROJECTS,
  SET_ACTIVE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_FAILED,
} from "../constants/project";

let initialState = {
  projects: [],
  loading: true,
  activeProject: {},
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };

    case GET_PROJECTS:
      return {
        ...state,
        loading: true,
      };

    case SET_PROJECTS:
      return {
        ...state,
        loading: false,
        projects: action.payload,
      };

    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: action.payload,
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        activeProject: action.payload,
      };

    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        projects: [...state.projects].filter((x) => x.id !== action.payload.id),
      };

    case DELETE_PROJECT_FAILED:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };

    default:
      return state;
  }
};

export default projectsReducer;
