import * as USER_ from "store/constants/user";

export const userDetailRequest = (payload) => ({
  type: USER_.ME_REQUEST,
  payload,
});

export const setUser = (payload) => ({
  type: USER_.SET_USER,
  payload,
});
