import * as actionType from "store/constants/inventory";

export const CreateNewColor = (payload) => ({
  type: actionType.CREATE_NEW_COLOR,
  payload,
});

export const CreateNewStyle = (payload) => ({
  type: actionType.CREATE_NEW_STYLE,
  payload,
});

export const CreateNewItemType = (payload) => ({
  type: actionType.CREATE_NEW_ITEM_TYPE,
  payload,
});

export const CreateInventoryItemRequest = (payload) => ({
  type: actionType.CREATE_INVENTORY_ITEM_REQUEST,
  payload,
});

export const CreateInventoryItemSuccess = (payload) => ({
  type: actionType.CREATE_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const GetInventoryItems = () => ({
  type: actionType.GET_INVENTORY_ITEMS,
});

export const SetInventoryItems = (payload) => ({
  type: actionType.SET_INVENTORY_ITEMS,
  payload,
});

export const GetInventoryOptions = () => ({
  type: actionType.GET_INVENTORY_OPTIONS,
});

export const SetInventoryColorOptions = (payload) => ({
  type: actionType.SET_INVENTORY_COLOR_OPTIONS,
  payload,
});

export const SetInventoryStyleOptions = (payload) => ({
  type: actionType.SET_INVENTORY_STYLE_OPTIONS,
  payload,
});

export const SetInventoryItemTypeOptions = (payload) => ({
  type: actionType.SET_INVENTORY_ITEM_TYPE_OPTIONS,
  payload,
});

export const UpdateInventoryRequest = (payload) => ({
  type: actionType.UPDATE_INVENTORY_ITEM_REQUEST,
  payload,
});

export const UpdateInventorySuccess = (payload) => ({
  type: actionType.UPDATE_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const DeleteInventoryRequest = (payload) => ({
  type: actionType.DELETE_INVENTORY_ITEM_REQUEST,
  payload,
});

export const DeleteInventorySuccess = (payload) => ({
  type: actionType.DELETE_INVENTORY_ITEM_SUCCESS,
  payload,
});
