import HomebuyerNavbar from "../../../components/navbar/homebuyerNavbar";
import React, {useState} from "react";
import HomeBuyerFooter from "../../../components/footer/homebuyerFooter";

import LoadingOverlay from "react-loading-overlay";

export default function ThankYou() {
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

    return (
        <>

            <HomebuyerNavbar/>
            <LoadingOverlay active={showLoadingOverlay} spinner text="Please wait...">
                <section className="with-bg"
                         style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)), url('/img/bg-new-2.png')"}}>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="success text-center text-light p-5 aos-init aos-animate"
                                     data-aos="zoom-in" data-aos-duration="800" data-aos-easing="ease-out-back">
                                    <img src="img/icon-success-lg.svg"/>
                                    <h1 className="font-1 mb-4">Thank you!</h1>

                                    <p className="mb-5">A representative will contact you shortly.</p>

                                    <div className="mb-4">
                                        <a href="/" className="btn btn-primary">Go back to
                                            homepage</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
            <HomeBuyerFooter/>
        </>
    )
        ;
}
