export const CREATE_NEW_COLOR = `CREATE_NEW_COLOR`;
export const CREATE_NEW_STYLE = `CREATE_NEW_STYLE`;
export const CREATE_NEW_ITEM_TYPE = `CREATE_NEW_ITEM_TYPE`;

export const CREATE_INVENTORY_ITEM_REQUEST = `CREATE_INVENTORY_ITEM_REQUEST`;
export const CREATE_INVENTORY_ITEM_SUCCESS = `CREATE_INVENTORY_ITEM_SUCCESS`;

export const GET_INVENTORY_ITEMS = `GET_INVENTORY_ITEMS`;
export const SET_INVENTORY_ITEMS = `SET_INVENTORY_ITEMS`;

export const GET_INVENTORY_OPTIONS = `GET_INVENTORY_OPTIONS`;

export const SET_INVENTORY_COLOR_OPTIONS = `SET_INVENTORY_COLOR_OPTIONS`;
export const SET_INVENTORY_STYLE_OPTIONS = `SET_INVENTORY_STYLE_OPTIONS`;
export const SET_INVENTORY_ITEM_TYPE_OPTIONS = `SET_INVENTORY_ITEM_TYPE_OPTIONS`;

export const UPDATE_INVENTORY_ITEM_REQUEST = "UPDATE_INVENTORY_ITEM_REQUEST";
export const UPDATE_INVENTORY_ITEM_SUCCESS = "UPDATE_INVENTORY_ITEM_SUCCESS";

export const DELETE_INVENTORY_ITEM_REQUEST = "DELETE_INVENTORY_ITEM_REQUEST";
export const DELETE_INVENTORY_ITEM_SUCCESS = "DELETE_INVENTORY_ITEM_SUCCESS";
