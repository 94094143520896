import { call, takeLatest, all, put } from "redux-saga/effects";

import * as CLIENT_ from "store/constants/client";
import {
  GetAllClient,
  UpdateClientService,
  GetAllVendorService,
  UpdateVendorService,
} from "services/clientServices";
import { SetClients, SetVendors } from "store/actions/client";

function* GetAllCLientRequest(action) {
  const { error, res } = yield call(GetAllClient);
  if (error) return;
  yield put(SetClients(res.data));
}

function* UpdateClientRequest(action) {
  const { resolve, reject, data, id } = action.payload;

  const { error, res } = yield call(UpdateClientService, id, data);

  if (error) {
    return reject();
  }

  resolve(res.data);
}

function* GetAllVendorRequest(action) {
  const { error, res } = yield call(GetAllVendorService);
  if (error) return;
  yield put(SetVendors(res.data));
}

function* UpdateVendorRequest(action) {
  const { resolve, reject, data, id } = action.payload;

  const { error, res } = yield call(UpdateVendorService, id, data);

  if (error) {
    return reject(error);
  }

  resolve(res);
}

/** ACTION WATCHERS */
function* ActionWatcher() {
  yield takeLatest(CLIENT_.GET_ALL_CLIENT, GetAllCLientRequest);
  yield takeLatest(CLIENT_.UPDATE_CLIENT, UpdateClientRequest);
  yield takeLatest(CLIENT_.GET_ALL_VENDOR, GetAllVendorRequest);
  yield takeLatest(CLIENT_.UPDATE_VENDOR, UpdateVendorRequest);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
