import { all } from "redux-saga/effects";

import AuthSaga from "./auth";
import UserSaga from "./user";
import ProjectSaga from "./project";
import InventorySaga from "./inventory";
import ModelSaga from "./models";
import StaffSaga from "./staff";
import ClientSaga from "./client";
import LeadSaga from "./lead";

export default function* RootSaga() {
  yield all([
    AuthSaga(),
    UserSaga(),
    ProjectSaga(),
    InventorySaga(),
    ModelSaga(),
    StaffSaga(),
    ClientSaga(),
    LeadSaga(),
  ]);
}
