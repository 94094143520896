/**
 * ModelThumbnailCard
 *  - Used for MODEL LIST in Community Detail page on Homebuyer Side
 *  - This component is quite similar to ModelListCard Component
 *  - This component is used if the view of models list is on thumbnail view
 *
 *  - PROPS
 *    - liked (Boolean) -> check if model is on the list of favorite models of the user
 *    - modelDetail (Object)  -> Detail of the model
 *      -> square_feet (Number)
 *      -> bedrooms_count (Number)
 *      -> bathrooms_count (Number)
 *      -> half_bath_count (Number)
 *      -> stories (Number)
 *      -> garage_count (Number)
 *      -> name (String)
 *      -> id (Number)
 *      -> main_image (String) -> URL or encoded to base64 image
 *      -> floor_plans (Array) -> List of floor_plan images
 *          - Check API for object structure
 *      -> price (Number)
 *      -> unity_body (Object or empty string)
 *          -> This would be an object if tour is created on 3D/Unity side
 *
 *
 *  - NEEDED COMPONENTS
 *    - FloorPlanModal -> show  model floor plans
 *
 */

import {useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";

import FloorPlanModal from "components/modals/FloorPlanModal";

import {FavoriteModel, UnfavoriteModel,} from "store/actions/models";

import {numberWithCommas} from "helpers/aux";
import {SetShowLoginModal} from "store/actions/auth";

const ModelThumbnailCard = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const {projectId} = match.params;
    const [showFloorplan, setShowFloorplan] = useState(false);
    const [heartHover, setHeartHover] = useState(false);
    const [calendarHover, setCalendarHover] = useState(false);

    const {user} = useSelector((state) => state.auth);

    const {
        modelDetail: {
            square_feet,
            bedrooms_count,
            bathrooms_count,
            name,
            id,
            main_image,
            floor_plans,
            unity_body,
            project: communityId,
            scene_urls,
        },
        liked,
    } = props;

    const ActionsHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        alert("This part is under construction!");
    };

    //onClick of the heart icon it would dispatch actions depending on model state
    const HeartClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        //if user is not logged in it would just show the login modal
        if (!Object.entries(user).length) {
            dispatch(SetShowLoginModal(true));
            return;
        }

        if (liked) {
            dispatch(UnfavoriteModel(props.modelDetail));
            return;
        }

        dispatch(FavoriteModel(props.modelDetail));
    };

    // onClick of the calendar icon, it should go to request-showing page
    const CalendarClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        //if user is not logged in it would just show the login modal
        if (!Object.entries(user).length) {
            dispatch(SetShowLoginModal(true));
            return;
        }

        history.push("/request-showing");
    };

    /*
    const ActionsHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      alert("This part is under construction!");
    };
    */

    let image_url =
        process.env.NODE_ENV === "development"
            ? `http://localhost:8000${main_image}`
            : main_image;

    return (
        <>
            <FloorPlanModal
                isOpen={showFloorplan}
                setModalState={setShowFloorplan}
                modelName={name}
                floor_plans={floor_plans}
            />
            <div className="col text-center">
                <div className="card card-item">
                    <div className="absolute-img">
                        <img
                            onClick={() =>
                                history.push(`/listings/${projectId}/model-detail/${id}`)
                            }
                            style={
                                !main_image
                                    ? {padding: "20%", cursor: "pointer"}
                                    : {cursor: "pointer"}
                            }
                            src={main_image ? image_url : "/img/logo-w.svg"}
                            className={classNames(
                                "card-img-top img-fluid",
                                !main_image && "bg-secondary",
                            )}
                            alt=""
                        />
                    </div>
                    <div className="card-body" style={{}}>
                        <div className="d-flex align-items-center mb-5">
                            <div className="me-auto title">
                                <span className="me-3">{name || "No model name"}</span>
                                <a href="#" className="me-3" onClick={HeartClickHandler}>
                                    <i
                                        onMouseOver={() => setHeartHover(true)}
                                        onMouseLeave={() => setHeartHover(false)}
                                        className={classNames(
                                            "fa-heart",
                                            liked ? "fas" : "far",
                                            heartHover ? "fas" : "far"
                                        )}
                                        aria-hidden="true"
                                    ></i>
                                </a>
                                <a href="#" className="me-3" onClick={CalendarClickHandler}>
                                    <i
                                        onMouseOver={() => setCalendarHover(true)}
                                        onMouseLeave={() => setCalendarHover(false)}
                                        className={classNames(
                                            "fa-calendar",
                                            calendarHover ? "fas" : "far"
                                        )}
                                        aria-hidden="true"
                                    ></i>
                                </a>
                                <a href="#" className="me-3"
                                   onClick={() => history.push(`/listings/${projectId}/model-detail/${id}`)}>
                                    <i className="fas fa-camera-retro" aria-hidden="true"></i>
                                </a>
                            </div>
                            {/* <div className="drop">
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                ></a>
                <ul className="dropdown-menu py-0" style={{ margin: "0px" }}>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(SetActiveModel(props.modelDetail));
                        history.push(
                          `/listings/${projectId}/model-detail/${id}`
                        );
                      }}
                    >
                      <img src="/img/ico-sm/photo.svg" className="me-2" />
                      Photos
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowFloorplan(true);
                      }}
                    >
                      <img src="/img/ico-sm/floor-plan.png" className="me-2" />
                      Floor Plan
                    </a>
                  </li>
                </ul>
              </div> */}
                        </div>

                        <div className="row row-cols align-items-start mb-4 mb-xl-0">
                            <div className="col">
                                <div className="ico-sm">
                                    <img className="d-block" src="/img/ico-sm/home-type.png"/>
                                    <ul className="list-unstyled">
                                        <li>Square Feet</li>
                                        <li>{numberWithCommas(square_feet)}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col">
                                <div className="ico-sm">
                                    <img className="d-block" src="/img/ico-sm/bed.png"/>
                                    <ul className="list-unstyled">
                                        <li>Bedrooms</li>
                                        <li>{bedrooms_count}</li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col">
                                <div className="ico-sm">
                                    <img className="d-block" src="/img/ico-sm/shower.png"/>
                                    <ul className="list-unstyled">
                                        <li>Bathrooms</li>
                                        <li>{bathrooms_count}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col">
                                <div className="ico-sm">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowFloorplan(true);
                                        }}
                                    >
                                        <img src="/img/ico-sm/floor-plan.png" className="d-block"/>
                                    </a>
                                    <ul className="list-unstyled">
                                        <li>Floorplan</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*name.toLowerCase() === magnolia is just temporary code*/}

                {scene_urls.length ? (
                    <a
                        href={scene_urls[0]}
                        className="btn btn-primary  my-3"
                    >
                        Take a tour
                    </a>
                ) : (
                    <a
                        href="#"
                        // className="btn btn-primary my-3"
                        className="btn btn-secondary my-3 disabled"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            alert("This part is under construction");
                        }}
                    >
                        Coming soon
                    </a>
                )}
            </div>
        </>
    );
};

export default ModelThumbnailCard;
