/*
 * HomebuyerProjectCard
 *  - PARENT COMPONENT
 *    -> SearchResults -> '/pages/homebuyer/listings/searchResults'
 *  - Used to display community/project details on the search results
 *  - onClick of this card will redirect user to commmunity detail page
 *
 *
 *  -PROPS
 *    - community (Object) -> Community details
 *      -> id (Number) - Community ID
 *      -> community_name (String)
 *      -> city (String)
 *      -> state (String) -> State where community is
 *      -> price_range_from (Number)
 *      -> community_type (String)
 *      -> home_type (String)
 *      -> main_image (String)
 */

import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

const HomebuyerProjectCard = (props) => {
  const history = useHistory();

  const {
    community: {
      id,
      community_name,
      city,
      state,
      price_range_from,
      community_type,
      home_type,
      main_image,
    },
  } = props;

  let image_url =
    process.env.NODE_ENV === "development"
      ? `http://localhost:8000${main_image}`
      : main_image;

  return (
    <div
      className="d-lg-flex"
      style={{ cursor: "pointer" }}
      onClick={() => history.push("/listings/models/" + id)}
    >
      <div
        className={classNames(
          "horizontal-thumb col-lg-4 mb-3",
          !main_image &&
            "d-flex  justify-content-center align-items-center bg-secondary"
        )}
      >
        <img
          src={main_image ? image_url : "/img/logo-w.svg"}
          alt="..."
          className="img-fluid"
        />
      </div>
      <div className="flex-grow-1 ms-3">
        <div className="row">
          <div className="col-lg-6">
            <h1>{community_name}</h1>
            <small>
              {city}, {state}
            </small>
          </div>
          <div className="col-lg-6 text-lg-end mt-3 mt-lg-0">
            <small>Priced From</small>
            <span className="price d-block">
              {price_range_from
                ? `Low $${price_range_from}s`
                : "No price range specified"}
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <ul className="list-unstyled">
              <li>
                <span>Community Type:</span>
                {community_type
                  ? community_type
                  : "No community type specified"}
              </li>
              <li>
                <span>Home Type:</span>{" "}
                {home_type ? home_type : "No home type specified"}
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 pt-5">
          {/*
          <div className="col compare">
            <div className="d-flex flex-row-reverse">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Compare
                </label>
              </div>
            </div>
          </div>

        */}
        </div>
      </div>
    </div>
  );
};

export default HomebuyerProjectCard;
