import React, { Component } from "react";
import { Player } from "furioos-sdk";

class Tour extends Component {
  constructor(props){
    super(props);

    this.modelName = new URLSearchParams(this.props.location.search).get('modelName');
    console.log(this.modelName);
  }
  componentDidMount() {
    const player = new Player("566.523", "gameContainer", {
      hidePlayButton: true,
      hideTitle: true,
    });

    this.player = player;
    player.onSDKMessage((data) => {
      console.log("SDK Message Received!");
      this.unityMessagehandler(JSON.parse(data));
    });
  }

  unityMessagehandler = (data) => {
    switch (data.event) {
      case "UNITY_ON_INIT":
        this.initializeUnity();
        return;

      case "BACK_TO_MODELS":
        //this.player.stop();
        this.props.history.push("/listings/models");
        return;

      default:
        return;
    }
  };

  initializeUnity = () => {
    console.log("Sending SDK Message...");
    this.player.sendSDKMessage({
      event: "JS_ON_INIT",
      payload: { modelName: this.modelName },
    });
  };

  render() {
    return <div id="gameContainer" style={{ height: "100vh" }} />;
  }
}

export default Tour;
