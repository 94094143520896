/**
 * ModelListCard
 *  - Used for MODEL LIST in Community Detail page on Homebuyer Side
 *  - This component is quite similar to ModelThumbnailCard Component
 *  - This component is used if the view of models list is on list view
 *
 *  - PROPS
 *    - liked (Boolean) -> check if model is on the list of favorite models of the user
 *    - modelDetail (Object)  -> Detail of the model
 *      -> square_feet (Number)
 *      -> bedrooms_count (Number)
 *      -> bathrooms_count (Number)
 *      -> half_bath_count (Number)
 *      -> stories (Number)
 *      -> garage_count (Number)
 *      -> name (String)
 *      -> id (Number)
 *      -> main_image (String) -> URL or encoded to base64 image
 *      -> floor_plans (Array) -> List of floor_plan images
 *          - Check API for object structure
 *      -> price (Number)
 *      -> unity_body (Object or empty string)
 *          -> This would be an object if tour is created on 3D/Unity side
 *
 *
 *  - NEEDED COMPONENTS
 *    - FloorPlanModal -> show  model floor plans
 *
 */

import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { numberWithCommas } from "helpers/aux";

import {
  SetActiveModel,
  FavoriteModel,
  UnfavoriteModel,
} from "store/actions/models";

import FloorPlanModal from "components/modals/FloorPlanModal";

import { SetShowLoginModal } from "store/actions/auth";

const ModelListCard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const [showFloorplan, setShowFloorplan] = useState(false);
  const [heartHover, setHeartHover] = useState(false);
  const [calendarHover, setCalendarHover] = useState(false);

  const { projectId } = match.params;

  const { user } = useSelector((state) => state.auth);

  const {
    modelDetail: {
      square_feet,
      bedrooms_count,
      bathrooms_count,
      half_bath_count,
      stories,
      garage_count,
      name,
      id,
      main_image,
      floor_plans,
      price,
      unity_body,
      project: communityId,
    },
    liked,
  } = props;

  const ActionsHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    alert("This part is under construction!");
  };

  //onClick of the heart icon it would dispatch actions depending on model state
  const HeartClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //if user is not logged in it would just show the login modal
    if (!Object.entries(user).length) {
      dispatch(SetShowLoginModal(true));
      return;
    }

    if (liked) {
      dispatch(UnfavoriteModel(props.modelDetail));
      return;
    }

    dispatch(FavoriteModel(props.modelDetail));
  };

  // onClick of the calendar icon, it should go to request-showing page
  const CalendarClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //if user is not logged in it would just show the login modal
    if (!Object.entries(user).length) {
      dispatch(SetShowLoginModal(true));
      return;
    }

    history.push("/request-showing");
  };

  let image_url =
    process.env.NODE_ENV === "development"
      ? `http://localhost:8000${main_image}`
      : main_image;

  return (
    <>
      <FloorPlanModal
        isOpen={showFloorplan}
        setModalState={setShowFloorplan}
        modelName={name}
        floor_plans={floor_plans}
      />
      <tr
        // onClick={() => {
        //   dispatch(SetActiveModel(props.modelDetail));
        //   history.push(`/listings/${projectId}/model-detail/${id}`);
        // }}
        // style={{ cursor: "pointer" }}
      >
        <td>
          <div className="d-flex align-items-center list-thumb">
            <div className="table-img"
              onClick={() => {
                dispatch(SetActiveModel(props.modelDetail));
                history.push(`/listings/${projectId}/model-detail/${id}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                style={
                  !main_image
                    ? {
                        paddingTop: "20%",
                        paddingBottom: "20%",
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        width: "100%",
                      }
                    : {
                        width: "100%",
                      }
                }
                src={main_image ? image_url : "/img/logo-w.svg"}
                className={
                  (classNames("img-fluid"), !main_image && "bg-secondary")
                }
                alt="..."
              />
            </div>
            <div className="ms-3">
              <div className="d-flex justify-content-between align-items-center">
                <span className="me-3 title">{name || "No model name"}</span>
                <div className="">
                  <a href="#" className="me-3" onClick={HeartClickHandler}>
                    <i
                      onMouseOver={() => setHeartHover(true)}
                      onMouseLeave={() => setHeartHover(false)}
                      className={classNames(
                        "fa-heart",
                        liked ? "fas" : "far",
                        heartHover ? "fas" : "far"
                      )}
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a href="#" className="me-3" onClick={CalendarClickHandler}>
                    <i
                      className={classNames(
                        "fa-calendar",
                        calendarHover ? "fas" : "far"
                      )}
                      aria-hidden="true"
                      onMouseOver={() => setCalendarHover(true)}
                      onMouseLeave={() => setCalendarHover(false)}
                    ></i>
                  </a>
                  <a href="#" className="me-3" onClick={() => history.push(`/listings/${projectId}/model-detail/${id}`)}>
                    <i className="fas fa-camera-retro" aria-hidden="true"></i>
                  </a>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#floorplan"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowFloorplan(true);
                    }}
                  >
                    <img src="/img/ico-sm/floor-plan.png" />{" "}
                  </a>
                </div>
              </div>
              {unity_body || name.toLowerCase() === "magnolia" ? (
                <a
                  href="#"
                  className="btn btn-primary  my-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push(
                      `/tour-360?modelId=${id}&communityId=${communityId}`
                    );
                  }}
                >
                  Take a tour
                </a>
              ) : (
                <a
                  href="#"
                  // className="btn btn-primary my-3"
                  className="btn btn-secondary my-3 disabled"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    alert("This part is under construction");
                  }}
                >
                  Coming soon
                </a>
              )}
            </div>
          </div>
        </td>
        <td>{price ? `$${numberWithCommas(price)}` : "No specified price"}</td>
        <td>
          <img src="/img/ico-sm/house.png" /> {numberWithCommas(square_feet)}
        </td>
        <td>
          <img src="/img/ico-sm/bed.png" /> {bedrooms_count}
          <br />
          {/*
        <span className="small">
          1<sup>st</sup>Floor Primary
        </span>
        */}
        </td>
        <td>
          <img src="/img/ico-sm/shower.png" /> {bathrooms_count}
        </td>
        <td>
          <img src="/img/ico-sm/bath.png" /> {half_bath_count}
        </td>
        <td>
          <img src="/img/ico-sm/garage.png" /> {garage_count}
        </td>
        <td>
          <img src="/img/ico-sm/stories.png" /> {stories}
        </td>
      </tr>
    </>
  );
};

export default ModelListCard;
