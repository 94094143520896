import * as actionTypes from "../constants/client";

let initialState = {
  list: [],
  vendors: [],
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SET_VENDORS:
      return {
        ...state,
        loading: false,
        vendors: action.payload,
      };

    default:
      return initialState;
  }
};

export default vendorReducer;
