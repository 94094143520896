import * as actionTypes from "../constants/models";

export const CreateModelRequest = (payload) => ({
  type: actionTypes.CREATE_MODEL_REQUEST,
  payload,
});

export const CreateModelSuccess = (payload) => ({
  type: actionTypes.CREATE_MODEL_SUCCESS,
  payload,
});

export const SetActiveModel = (payload) => ({
  type: actionTypes.SET_ACTIVE_MODEL,
  payload,
});

export const GetFavoriteModels = () => ({
  type: actionTypes.GET_FAVORITE_MODELS,
});

export const SetFavoriteModels = (payload) => ({
  type: actionTypes.SET_FAVORITE_MODELS,
  payload,
});

export const FavoriteModel = (payload) => ({
  type: actionTypes.FAVORITE_MODEL,
  payload,
});

export const UnfavoriteModel = (payload) => ({
  type: actionTypes.UNFAVORITE_MODEL,
  payload,
});

export const UpdateFavoriteModel = (payload) => ({
  type: actionTypes.UPDATE_FAVORITE_MODEL,
  payload,
});

export const GetProjectModels = (payload) => ({
  type: actionTypes.GET_PROJECT_MODELS,
  payload,
});

export const SetProjectModels = (payload) => ({
  type: actionTypes.SET_PROJECT_MODELS,
  payload,
});

export const UpdateModelRequest = (payload) => ({
  type: actionTypes.UPDATE_MODEL_REQUEST,
  payload,
});

export const UpdateModelSuccess = (payload) => ({
  type: actionTypes.UPDATE_MODEL_SUCCESS,
  payload,
});

export const DeleteModelRequest = (payload) => ({
  type: actionTypes.DELETE_MODEL_REQUEST,
  payload,
});

export const DeleteModelFailed = (payload) => ({
  type: actionTypes.DELETE_MODEL_FAILED,
  payload,
});
