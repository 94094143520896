

/*
 * SendSMSModal
 *  - Modal component that just takes in the number of the recipient of the sms
 *
 *  - NEEDED COMPONENTS
 *    - PhoneInput
 *
*/

import { useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal";
import PhoneInput from "components/input/PhoneInput";

const SendSMSModal = (props) => {
  const { isOpen, setModalState, submitHandler } = props;
  const { register, handleSubmit } = useForm();

  const submitForm = (data) => {
    const cleanedPhone = data.phone_number.replace(/\D/g, "");
    submitHandler(cleanedPhone);
  };

  return (
    <Modal show={isOpen} centered onHide={() => setModalState(false)}>
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-5 pb-3">
        <h1
          className="mb-2"
          style={{ fontSize: "18px", textTransform: "none", fontWeight: 600 }}
        >
          Share to SMS
        </h1>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="mb-4">
            <label className="form-label mt-4">Phone Number</label>
            <PhoneInput
              className="form-control"
              placeholder="Enter Phone Number"
              ref={register}
              name="phone_number"
              required
            />
            {/*
          <label for="" class="form-label mt-4">
            Message
          </label>
          <textarea class="form-control" id="" rows="3" placeholder="">
            Hey there, check this lorem ipsum dolor sit amet! Here’s the link:
            3dconsumer.com
          </textarea>
          */}
            <button
              className="btn btn-primary mt-4 w-100 d-block d-md-inline-block"
              type="submit"
            >
              Send Message
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SendSMSModal;
