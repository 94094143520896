import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Route, Switch} from "react-router-dom";

import LoadingOverlay from "react-loading-overlay";
import HomebuyerNavbar from "components/navbar/homebuyerNavbar";
import HomeBuyerFooter from "components/footer/homebuyerFooter";
import LandingPage from "./landing";
import AccountSettings from "./account";

const Vendor = (props) => {
    const dispatch = useDispatch();

    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

    const query = new URLSearchParams(props.location.search);
    const openLoginModal = query.get("openLoginModal");

    return (
        <>
            <HomebuyerNavbar openLoginModal={openLoginModal}/>
            <LoadingOverlay active={showLoadingOverlay} spinner text="Please wait...">
                <Switch>
                    <Route
                        path={props.match.url + "/account-settings"}
                        exact
                        component={AccountSettings}
                    />
                    <Route path={props.match.url} exact>
                        <LandingPage/>
                    </Route>
                </Switch>
            </LoadingOverlay>
            <HomeBuyerFooter/>
        </>
    );
};

export default Vendor;
