import * as actionTypes from "../constants/models";

let initialState = {
  list: [],
  active: {},
  favorites: [],
  projectModels: {},
};

const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MODEL_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
      };

    case actionTypes.SET_ACTIVE_MODEL:
      return {
        ...state,
        active: action.payload,
      };

    case actionTypes.SET_FAVORITE_MODELS:
      return {
        ...state,
        favorites: action.payload,
      };

    /*
    case actionTypes.FAVORITE_MODEL:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      };
      */

    case actionTypes.UNFAVORITE_MODEL:
      return {
        ...state,
        favorites: [...state.favorites].filter(
          (x) => x.model.id !== action.payload.id
        ),
      };

    case actionTypes.SET_PROJECT_MODELS:
      return {
        ...state,
        projectModels: {
          ...state.projectModels,
          [action.payload.projectId]: action.payload.models,
        },
      };

    case actionTypes.DELETE_MODEL_REQUEST:
      return {
        ...state,
        projectModels: {
          ...state.projectModels,
          [action.payload.project]: [
            ...state.projectModels[action.payload.project].filter(
              (item) => item.id !== action.payload.id
            ),
          ],
        },
      };

    case actionTypes.DELETE_MODEL_FAILED:
      return {
        ...state,
        projectModels: {
          ...state.projectModels,
          [action.payload.project]: [
            ...state.projectModels[action.payload.project],
            { ...action.payload },
          ],
        },
      };

    default:
      return initialState;
  }
};

export default modelReducer;
