/*
 * HomeBuyerFooter
 *  - This component is the reused footer on all the pages
 *  - No props required as this works independently
*/


import React, {useEffect} from "react";
import * as bootstrap from "react-bootstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";

const HomeBuyerFooter = (props) => {
    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        });
    }, [])

    const renderTooltip = (props) => {
        return (
            <Tooltip id="system-requirements-tooltip" {...props}>
                <b>Minimum:</b>
                <ul className='list-unstyled'>
                    <li>Intel i5 8250U or equivalent</li>
                    <li>Intel HD Graphics 620 or equivalent</li>
                    <li>8GB DDR4</li>
                    <li>Windows 10</li>
                </ul>
                <b>Recommended:</b>
                <ul className='list-unstyled'>
                    <li>Ryzen 5 3600 or equivalent</li>
                    <li>GTX 1660 or equivalent</li>
                    <li>16GB DDR4</li>
                    <li>Windows 10</li>
                </ul>
                <b>Maximum:</b>
                <ul className='list-unstyled'>
                    <li>Ryzen 7 2700x or equivalent</li>
                    <li>RTX 3080 or equivalent</li>
                    <li>32GB DDR4</li>
                    <li>Windows 10</li>
                </ul>
            </Tooltip>
        );
    }

    return (
        <footer className="footer-v2">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="border-bottom mb-5">
                            <h1 className="text-center pb-3">
                                Offered exclusively through Virtual Reality Inc.
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-5">
                    <div className="col-md-12 col-lg text-center text-lg-start">
                        <ul className="list-unstyled" style={{fontSize: "1rem"}}>
                            <li className="mb-3"><a href="/" className="brand me mb-3"><img src="/img/logo-w.svg"
                                                                                            className=""/></a></li>
                            <li className="mb-2"><a href="tel:9544481074"><i className="fas fa-phone-alt me-2"
                                                                aria-hidden="true"></i>954-448-1074</a></li>
                            <li className="mb-2"><a href="mailto:sales@3dconsumer.com"><i className="far fa-envelope me-2"
                                                                aria-hidden="true"></i> sales@3dconsumer.com</a></li>
                            <li className="mb-2">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTooltip}>
                                    <a>
                                    <span className="text-white">System Requirements {" "}
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>


                            <li className="mb-2">
                                <a href="/toc">
                                    {"Terms and Conditions"}
                                </a>
                            </li>
                        </ul>

                    </div>
                    <div className="col-md-12 col-lg">
                    </div>
                </div>


            </div>
            <div className="copyright text-center mt-3">
                VIRTUAL REALITY INC. COPYRIGHT © 2022
            </div>
        </footer>
    );
};

export default HomeBuyerFooter;
