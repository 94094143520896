import { SET_USER } from "store/constants/user";
import { LOGOUT, SET_SHOW_LOGIN_MODAL } from "store/constants/auth";

import { unsetAuthHeaderToken } from "services";

let initialState = {
  user: {},
  showLoginModal: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.payload };
    }

    case LOGOUT: {
      localStorage.removeItem("auth_token");
      unsetAuthHeaderToken();
      return { user: initialState.user };
    }
    case SET_SHOW_LOGIN_MODAL: {
      return { ...state, showLoginModal: action.payload };
    }

    default:
      return state;
  }
};

export default authReducer;
