/*
 * AddUserModal
 *  - THIS COMPONENT IS USED IN SUPERADMIN SIDE
 *  - This component is used for adding user in the staff management
 *  - modal is shown upon clicking "Add User" button
 */

import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";

import { UpdateStaffRequest } from "store/actions/staff";
import {
  CreateSuccessNotification,
  CreateErrorNotification,
} from "helpers/notification";

import PhoneInput from "components/input/PhoneInput";

const EditUserModal = (props) => {
  const { isOpen, setModalState, userData = null, refresh } = props;

  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const [isUpdating, setIsUpdating] = useState(false);

  let isAdmin = userData?.role === "ADMIN" ? true : false;

  const _setupNewValue = (update = {}) => {
    let payload = {};

    // /** Setup original data on payload */
    // password: "string", // TODO: confirm what will happen to password is left empty

    payload.first_name =
      userData.first_name == update.first_name.trim() || update.first_name == ""
        ? userData.first_name
        : update.first_name;

    payload.last_name =
      userData.last_name == update.last_name.trim() || update.last_name == ""
        ? userData.last_name
        : update.last_name;

    payload.email =
      userData.email == update.email.trim() || update.email == ""
        ? userData.email
        : update.email;

    payload.address =
      userData.address == update.address.trim() || update.address == ""
        ? userData.address
        : update.address;

    payload.phone =
      userData.phone == update.phone.trim() || update.phone == ""
        ? userData.phone
        : update.phone;

    payload.role = update.role;

    return payload;
  };

  const UpdateStaffData = (data) => {
    const id = userData.id;

    setIsUpdating(true);

    /** Cross check new form data if fields are empty or with same value */
    let payload = _setupNewValue(data);

    new Promise((resolve, reject) => {
      dispatch(UpdateStaffRequest({ resolve, reject, data: payload, id }));
    })
      .then(() => {
        CreateSuccessNotification("Successfully created staff!");
        setIsUpdating(false);

        setModalState(false);
      })
      .catch((err) => {
        CreateErrorNotification(err.data);
        CreateErrorNotification("Try Updating Again!");
      })
      .finally(() => {
        refresh(true);
      });
  };

  return (
    <Modal show={isOpen} centered className="">
      <Modal.Header className="px-4">
        <h2 className="form-title mb-0 add-user-modal-header-text text-uppercase">
          {" EDIT Users "}
        </h2>
        <button
          type="button"
          className="btn-close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-4 pb-5">
        <form className="row mb-4" onSubmit={handleSubmit(UpdateStaffData)}>
          <div className="row mb-3">
            <div className="col-12 mb-4">
              <label for="" className="form-label">
                Email Address
              </label>
              <input
                ref={register}
                type="text"
                className="form-control"
                placeholder="Email"
                name="email"
                id="email"
                defaultValue={userData?.email}
                disabled={isUpdating}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label for="" className="form-label">
                First Name
              </label>
              <input
                ref={register}
                className="form-control"
                id="first_name"
                placeholder="First Name"
                defaultValue={userData?.first_name}
                name="first_name"
                disabled={isUpdating}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label for="" className="form-label">
                Last Name
              </label>
              <input
                ref={register}
                type="text"
                className="form-control"
                id="last_name"
                placeholder="Last"
                defaultValue={userData?.last_name}
                name="last_name"
                disabled={isUpdating}
              />
            </div>
            <div className="col-md-12 mb-4">
              <label for="" className="form-label">
                Phone Number
              </label>
              <PhoneInput
                ref={register}
                name="phone"
                className="form-control"
                placeholder="Phone Number"
                type="tel"
                defaultValue={userData?.phone}
                disabled={isUpdating}
              />
            </div>
            <div className="col-md-12 mb-4">
              <label for="" className="form-label">
                Address
              </label>
              <input
                ref={register}
                type="text"
                className="form-control"
                id="address"
                placeholder="Address"
                // value={userData?.address}
                defaultValue={userData?.address}
                name="address"
                disabled={isUpdating}
              />
            </div>
            <div className="col-md-12 mb-4">
              <label for="" className="form-label">
                Account Type
              </label>
              <select
                ref={register}
                name="role"
                id="role"
                className="form-control form-control-select"
                required
                disabled={isUpdating}
              >
                <option value="ADMIN" selected={isAdmin}>
                  Admin
                </option>
                <option value="STAFF" selected={!isAdmin}>
                  Staff
                </option>
              </select>
            </div>
            {/* <div className="col-md-12 mb-4">
            <label for="" className="form-label">
              Password
            </label>
            <input
              type="text"
              className="form-control"
              id="password"
              placeholder="Password"
              value={userData?.password}
            />
          </div> */}
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <button
                className="btn btn-solid-blue mr-2 w-100 rounded-0 btn-edit-staff"
                disabled={isUpdating}
              >
                SAVE CHANGES
              </button>
            </div>
            <div className="col-md-6 mb-3">
              <button
                className="btn btn-outline-blue w-100 rounded-0"
                onClick={(e) => (e.preventDefault(), setModalState(false))}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeModel: state.models.active,
});

export default connect(mapStateToProps)(EditUserModal);
