/*
 * LoginModal
 *  - This component is used for the login form of the application
 *  - This component can be controlled in by setting a state in redux store by setting showLoginModal to true
 *    - {...reduxStore, auth: { showLoginModal: true }}
 *
 *  - PROPS
 *    - showLoginModal (Boolean) -> state of the modal
 *    - setShowLoginModal (Function) -> Function that controls the value of showLoginModal value in parent component
 */

import React, { useState, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  AuthLoginRequest,
  Logout,
  SetShowLoginModal,
} from "store/actions/auth";
import { CreateErrorNotification } from "helpers/notification";

const LoginModal = (props) => {
  const { showLoginModal, setShowLoginModal } = props;

  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { register, handleSubmit } = useForm();

  const {
    user: { is_superuser },
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleLogin = (data) => {
    setSubmitting(true);
    new Promise((resolve, reject) => {
      dispatch(AuthLoginRequest({ ...data, resolve, reject }));
    })
      .then(() => {
        setShowLoginModal(false);
        history.push(location.pathname);
      })
      .catch((err) => CreateErrorNotification(err.data.error))
      .finally(() => {
        setSubmitting(false);
      });
  };

  useMemo(() => {
    if (is_superuser) {
      props.history.push("/");
    }
  }, [is_superuser]);

  return (
    <Modal
      show={showLoginModal}
      centered
      onExit={() => dispatch(SetShowLoginModal(false))}
    >
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowLoginModal(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="px-5 pb-5 form-modal">
        <form onSubmit={handleSubmit(handleLogin)}>
          <h1 className="mb-4">Login</h1>
          <div className="mb-3">
            <label htmlFor="" className="form-label">
              Email address
            </label>
            <input
              ref={register}
              name="email"
              type="email"
              className="form-control"
              id=""
              placeholder="Enter Email Address"
            />
            <label htmlFor="" className="form-label mt-4">
              Password
            </label>
            <div className="input-group pass-word" id="show_hide_password">
              <input
                ref={register}
                name="password"
                className="form-control"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
              />
              <div className="input-group-addon">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  <i
                    className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
            <div className="text-end mt-3 mb-4">
              <Link
                to="/forgot-password"
                className="link"
                onClick={() => {
                  setShowLoginModal(false);
                  dispatch(SetShowLoginModal(false));
                }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>

          <div className="d-grid mb-4">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={submitting}
            >
              Login
            </button>
          </div>
          <div className="text-center text-uppercase">
            <span>
              Don't have an Account?
              <Link to="/register" className="link fw-bold ms-1">
                Sign Up Here
              </Link>
            </span>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(LoginModal);
