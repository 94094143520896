import {
  CREATE_NEW_COLOR,
  CREATE_NEW_STYLE,
  CREATE_NEW_ITEM_TYPE,
  CREATE_INVENTORY_ITEM_SUCCESS,
  SET_INVENTORY_ITEMS,
  SET_INVENTORY_COLOR_OPTIONS,
  SET_INVENTORY_STYLE_OPTIONS,
  SET_INVENTORY_ITEM_TYPE_OPTIONS,
  UPDATE_INVENTORY_ITEM_SUCCESS,
  DELETE_INVENTORY_ITEM_SUCCESS,
} from "store/constants/inventory";

let initialState = {
  itemTypeOptions: [],
  colorOptions: [],
  styleOptions: [],
  items: [],
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_COLOR:
      return {
        ...state,
        colorOptions: [...state.colorOptions, action.payload],
      };

    case CREATE_NEW_STYLE:
      return {
        ...state,
        styleOptions: [...state.styleOptions, action.payload],
      };

    case CREATE_NEW_ITEM_TYPE:
      return {
        ...state,
        itemTypeOptions: [...state.itemTypeOptions, action.payload],
      };

    case SET_INVENTORY_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case CREATE_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload],
      };

    case SET_INVENTORY_COLOR_OPTIONS:
      return {
        ...state,
        colorOptions: action.payload,
      };

    case SET_INVENTORY_ITEM_TYPE_OPTIONS:
      return {
        ...state,
        itemTypeOptions: action.payload,
      };

    case SET_INVENTORY_STYLE_OPTIONS:
      return {
        ...state,
        styleOptions: action.payload,
      };

    case UPDATE_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id !== action.payload.id) {
            return item;
          }
          return action.payload;
        }),
      };

    case DELETE_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.filter(
          (item) => parseInt(action.payload.id) !== item.id
        ),
      };

    default:
      return state;
  }
};

export default inventoryReducer;
