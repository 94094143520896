/*
 * HomebuyerNavbar
 *  - Used in all the homebuyer pages
 *
 *  - NEEDED COMPONENTS
 *    - LoginModal -> LoginModal is controlled in here
 */

import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";

import LoginModal from "components/modals/LoginModal";
import {NavLink} from "react-bootstrap";

const HomebuyerNavbar = (props) => {
    const {user, showLoginModal: showLoginModalRedux} = useSelector(
        (state) => state.auth
    );

    const {openLoginModal = false, isSolid = false} = props;
    const [showLoginModal, setShowLoginModal] = useState(
        openLoginModal === "true" || showLoginModalRedux
    );
    const [solidNav, setSolidNav] = useState(false);
    const dispatch = useDispatch();

    let authenticated = Object.entries(user).length > 0;

    // Checks if user is on the top of the page
    // to modify navbar if should be solid or not
    const handleScroll = () => {
        if (
            (document.body.scrollTop >= 100 ||
                document.documentElement.scrollTop >= 100) &&
            !document.URL.includes("/settings")
        ) {
            setSolidNav(true);
            return;
        }
        setSolidNav(false);
    };

    useEffect(() => {
        if (!isSolid) {
            window.addEventListener("scroll", handleScroll);
        }
        setSolidNav(isSolid);

        setShowLoginModal(showLoginModalRedux);

        return () => {
            if (!isSolid) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, [showLoginModalRedux, isSolid]);

    return (
        <>
            <nav
                className={classNames(
                    "navbar fixed-top navbar-expand-md navbar-dark mb-4",
                    solidNav ? "nav-solid" : "nav-transparent"
                )}
                id="nav"
            >
                <div className="container-fluid flex-column flex-md-row">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <a className="navbar-brand" href="/">
                            <img src="img/logo-w.svg"/>
                        </a>
                    </div>

                    <div className="d-flex align-items-center middle-head">
                    <span className="fs-25 txt-color-white fw-400 text-center fw-bold">
                        Virtual Reality Design Centers
                    </span>
                    </div>

                    <div className="d-flex">
                        <a href="tel:954-448-1074" className="nav-link"><i className="fas fa-phone"
                                                                           aria-hidden="true"></i></a>
                        <a className="nav-link" href="/lets-talk">Let's Talk</a>
                    </div>
                </div>
            </nav>
            <LoginModal
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
            />
        </>
    );
};

export default withRouter(HomebuyerNavbar);
