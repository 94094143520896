/*
 * This component is for the repeating success page main content
 *
 * - PROPS
 *   - message (String) -> Message on the Success page
 *   - redirectPath (String) -> path where to redirect user onclick of the button
 *   - buttonText (String) -> text that will be displayed on the button
 */

import { useHistory } from "react-router-dom";

const SuccessComponent = (props) => {
  const { message, redirectPath, buttonText } = props;
  const history = useHistory();

  return (
    <div
      className="success text-center text-light p-5"
      data-aos="zoom-in"
      data-aos-duration="800"
      data-aos-easing="ease-out-back"
    >
      <img src="/img/icon-success-lg.svg" />
      <h1 className="font-1 mb-4">Success!</h1>
      <p className="mb-5">{message}</p>
      <div className="mb-4">
        <a
          href="#"
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            history.push(redirectPath);
          }}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

export default SuccessComponent;
