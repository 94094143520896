import * as CLIENT_ from "store/constants/client";

export const GetAllClient = () => ({
  type: CLIENT_.GET_ALL_CLIENT,
});

export const SetClients = (payload) => ({
  type: CLIENT_.SET_CLIENTS,
  payload,
});

export const UpdateClientRequest = (payload) => ({
  type: CLIENT_.UPDATE_CLIENT,
  payload,
});

export const GetAllVendor = () => ({
  type: CLIENT_.GET_ALL_VENDOR,
});

export const SetVendors = (payload) => ({
  type: CLIENT_.SET_VENDORS,
  payload,
});

export const UpdateVendorRequest = (payload) => ({
  type: CLIENT_.UPDATE_VENDOR,
  payload,
});
