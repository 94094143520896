import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ModelThumbnailCard, ModelListCard } from "components/cards/models";

import { getModelsService } from "services/modelServices";
import { getProjectService } from "services/projectServices";
import { GetImageUrl, numberWithCommas } from "helpers/aux";
import { GetFavoriteModels } from "store/actions/models";

import LoadingOverlay from "react-loading-overlay";

const ModelList = (props) => {
  const history = useHistory();
  const { projectId } = props.match.params;
  const [viewMode, setViewMode] = useState("thumbnail"); // thumbnail or list
  const [project, setProject] = useState("");
  const [projectModels, setProjectModels] = useState([]);

  const scrollDiv = React.useRef(null);
  const [arrowDisplay, setArrowDisplay] = useState(false);

  const dispatch = useDispatch();
  const { favorites } = useSelector((state) => state.models);

  const favoriteModels = [...favorites];

  const handleScroll = () => {
    if (
      (document.body.scrollTop >= 100 ||
      document.documentElement.scrollTop >= 100)
    ) {
      setArrowDisplay(true);
      return;
    }
    setArrowDisplay(false);
  };

  useEffect(async () => {
    dispatch(GetFavoriteModels());

    window.scrollTo(0, 0);
    let { res: project, err: projectErr } = await getProjectService(projectId);
    let { res: projectModels, err: modelsErr } = await getModelsService(
      projectId
    );

    window.addEventListener("scroll", handleScroll);

    setProject(project.data);
    setProjectModels(projectModels.data);

  }, []);

  const scrollDown = () => {
    // scrollDiv.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    let elementPosition = scrollDiv.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - 75;
    
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });  
  }

  if (!projectId) {
    props.history.push("/");
  }

  const {
    community_name,
    city,
    state,
    home_type,
    community_type,
    main_image,
    price_range_from,
    contacts = [],
  } = project;

  let image_url =
    process.env.NODE_ENV === "development"
      ? `http://localhost:8000${main_image}`
      : main_image;

  if (!project) {
    return (
      <section style={{ height: "60vh" }}>
        <h1 style={{ textAlign: "center", paddingTop: "10%" }}>Loading...</h1>
      </section>
    );
  }

  return (
    <>
      <section
        className="header-lg p-0 g-0"
        style={{ fontFamily: "Open Sans" }}
        // style={
        //   // main_image ? { fontFamily: "Open Sans", backgroundImage: image_url} : { fontFamily: "Open Sans", backgroundImage: 'url("/img/logo-w.svg")'}
        //   // { fontFamily: "Open Sans", backgroundImage: 'url("/img/img-sample-2.jpg")'}
        //   // { fontFamily: "Open Sans", backgroundImage: GetImageUrl(image_url)}
        //   { fontFamily: "Open Sans", backgroundImage: image_url}
        // }
      >
        <div className="container-fluid">
          <div className="justify-content-center">
            <div className="col">
              <div
                className={classNames(
                  !main_image &&
                    "d-flex  justify-content-center align-items-center bg-secondary"
                )}
                style={
                  !main_image ? { paddingLeft: "15%", paddingRight: "15%" } : {}
                }
              >
                <img
                  src={main_image ? image_url : "/img/logo-w.svg"}
                  style={
                    main_image ? { objectFit: "cover" } : { height: "50em" }
                  }
                />
                <div className="container-fluid">
                  <div className="justify-content-center">
                    <div className="col">
                      <div className="hero-txt"> 
                          <div className="txt-content">
                              <h2 className="text-center">Our Mission</h2>
                              <p className="my-3">To make buying, customizing and purchasing new construction properties a fun, fast and easy process.</p>
                              <a href="#" className="btn btn-primary mt-4"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  process.env.NODE_ENV === "development" ?
                                    history.push(`/tour-360?modelId=${2}&communityId=${3}`) :
                                    history.push(`/tour-360?modelId=${1}&communityId=${3}`); // to be replaced with proper Get Started
                                }}
                              >Get Started</a>
                          </div>
                      </div>  
                    </div>
                  </div>
                </div>
              </div>
              <div className="name-lg ps-3 ps-lg-5">
                <div className="mb-0 name">
                  <span>{community_name}</span>{" "}
                  <i className="fas fa-heart" aria-hidden="true"></i>
                </div>
                <div className="mb-2 mb-lg-5 loc">
                  {city}, {state}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={classNames(
        "col-12",
        arrowDisplay ? "custom-hide" : ""
      )}>
        <a href="#"
          onClick={(e) => {
            e.preventDefault();
            scrollDown();
        }}>
          <img src="/img/arrow-down.svg" className="arrow-down m-4" alt="" />
        </a>
      </div>
      <section className="pt-5" style={{ fontFamily: "Open Sans" }} ref={scrollDiv}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 align-items-center mb-5">
              <div className="row row-cols-1 row-cols-lg-4 align-items-center mb-4 mb-xl-0">
                <div className="col-lg-2">
                  <div className="ico-sm">
                    <img className="d-block" src="/img/ico-sm/home-type.png" />
                    <ul className="list-unstyled">
                      <li>Home Type</li>
                      <li>{home_type || "No home type specified"}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="ico-sm">
                    <img className="d-block" src="/img/ico-sm/community.png" />
                    <ul className="list-unstyled">
                      <li>Community Type</li>
                      <li>{community_type || "No community type specified"}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="ico-sm">
                    <img className="d-block" src="/img/dollar-icon.svg" />
                    <ul className="list-unstyled">
                      <li>Priced From</li>
                      <li>
                        {price_range_from
                          ? `$${numberWithCommas(price_range_from)}`
                          : "No specified price"}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="my-3">
                    <a
                      href="#"
                      className="btn btn-primary d-block m-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/contact-us");
                      }}
                    >
                      Talk to a community expert
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center view-row mb-3">
            <div className="col-6 ">
              <h1>Home Designs</h1>
            </div>
            <div className="col-6 text-end">
              <div className="views">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setViewMode("thumbnail");
                  }}
                  href=""
                  className={classNames(
                    "d-inline-block",
                    viewMode === "thumbnail" && "active"
                  )}
                >
                  <i className="fas fa-th-large" aria-hidden="true"></i>
                </a>
                <a
                  href=""
                  className={classNames(
                    "d-inline-block",
                    viewMode === "list" && "active"
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setViewMode("list");
                  }}
                >
                  <i className="fas fa-list" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          {/* THUMBNAIL */}
          {viewMode === "thumbnail" &&
            (projectModels.length ? (
              <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 gy-4">
                {projectModels.map((item) => {
                  return (
                    <ModelThumbnailCard
                      key={item.id}
                      modelDetail={item}
                      liked={
                        favoriteModels.filter((x) => x?.model.id === item.id)
                          .length > 0
                      }
                    />
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-exclamation-circle color-1 fa-2x mb-3"
                    aria-hidden="true"
                  ></i>
                  <h1 className="font-1">No Home Designs yet</h1>
                  <p>Check back later</p>
                </div>
              </div>
            ))}
          {viewMode === "list" && (
            <div className="row">
              <div className="col">
                <div className="table-responsive text-nowrap mb-5">
                  <table className="table table-list-view align-middle">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Square Feet</th>
                        <th scope="col">Bedrooms</th>
                        <th scope="col">Bathrooms</th>
                        <th scope="col">Half Bath</th>
                        <th scope="col">Garages</th>
                        <th scope="col">Stories</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectModels.length ? (
                        projectModels.map((item) => (
                          <ModelListCard
                            key={item.id}
                            modelDetail={item}
                            liked={
                              favoriteModels.filter(
                                (x) => x?.model.id === item.id
                              ).length > 0
                            }
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <i
                              className="fas fa-exclamation-circle color-1 fa-2x mb-3"
                              aria-hidden="true"
                            ></i>
                            <h1 className="font-1">No Home Designs yet</h1>
                            <p>Check back later</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ModelList;
