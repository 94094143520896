import { call, takeLatest, all, put } from "redux-saga/effects";

import {
  createProjectService,
  getProjectsService,
  getProjectService,
  UpdateProjectService,
  DeleteProjectService,
} from "services/projectServices";
import * as ProjectActions from "../constants/project";
import {
  CreateProjectSucccess,
  SetProjects,
  SetActiveProject,
  UpdateProjectSuccess,
  DeleteProjectFailed,
} from "../actions/project";

import { CreateErrorNotification } from "helpers/notification";

export function* CreateProjectFlow(action) {
  const { resolve, reject, payload } = action.payload;

  const { err, res } = yield call(createProjectService, payload);

  if (err) {
    return reject(err.data);
  }
  yield put(CreateProjectSucccess(res.data));
  resolve(res.data);
}

export function* GetProjectsFlow(action) {
  const { err, res } = yield call(getProjectsService);
  if (err) return;
  yield put(SetProjects(res.data));
}

function* GetActiveProjectFlow(action) {
  const { resolve, reject, id } = action.payload;

  const { err, res } = yield call(getProjectService, id);
  if (err) {
    reject(err.data);
    return;
  }

  resolve(res.data);
  yield put(SetActiveProject(res.data));
}

function* UpdateProjectFlow(action) {
  const { resolve, reject, id, payload } = action.payload;
  const { res, err } = yield call(UpdateProjectService, id, payload);

  if (err) {
    reject(err.data);
    return;
  }

  resolve(res.data);
  yield put(UpdateProjectSuccess(res.data));
}

function* DeleteProjectFlow(action) {
  const { err } = yield call(DeleteProjectService, action.payload.id);
  if (err) {
    yield put(DeleteProjectFailed(action.payload));
    return;
  }
}

function* ActionWatcher() {
  yield takeLatest(ProjectActions.CREATE_PROJECT_REQUEST, CreateProjectFlow);
  yield takeLatest(ProjectActions.GET_PROJECTS, GetProjectsFlow);
  yield takeLatest(ProjectActions.GET_ACTIVE_PROJECT, GetActiveProjectFlow);
  yield takeLatest(ProjectActions.UPDATE_PROJECT_REQUEST, UpdateProjectFlow);
  yield takeLatest(ProjectActions.DELETE_PROJECT_REQUEST, DeleteProjectFlow);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
