import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import HomebuyerProjectCard from "components/cards/homebuyerProjectCard";
import { searchProjectService } from "services/projectServices";

const SearchResults = (props) => {
  const query = new URLSearchParams(useLocation().search);
  let q = query.get("q");

  const history = useHistory();
  const [search, setSearch] = useState("");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    let q = query.get("q");
    setSearch(q);
    searchProjectService(q).then(({ res, err }) => {
      if (res) {
        setProjects(res.data);
        return;
      }
      setProjects([]);
    });
  }, [q]);

  const SearchHandler = (e) => {
    e.preventDefault();
    history.push(`/listings/search?q=${search}`);
  };

  return (
    <>
      <section className="header with-bg text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col">
              <h1 className="mb-3">Your Search Results</h1>
              <p>Select the property you would like to view and customize </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form onSubmit={SearchHandler}>
                <div className="input-group mb-3 mt-3">
                  <i
                    className="fas fa-search ps-4 ps-md-4"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    className="form-control me-0 me-lg-3"
                    placeholder=""
                    aria-label=""
                    aria-describedby="basic-addon2"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button type="submit" className="btn btn-primary me-3">
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5">
        <div className="container">
          <div className="row">
            {projects.length !== 0 ? (
              <div className="col">
                <form className="row row-cols-lg-auto g-3  align-items-center">
                  <div className="col">
                    <label>Sort by</label>
                  </div>
                  <div className="col">
                    <div className="input-group">
                      <select
                        className="form-select"
                        aria-label=" "
                        defaultValue=""
                      >
                        <option value="1">A to Z</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                      </select>
                    </div>
                  </div>
                </form>
                {projects.map((item) => (
                  <React.Fragment key={item.id}>
                    <div className="box-result-item my-5">
                      <HomebuyerProjectCard community={item} />
                    </div>
                    <hr />
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className="col text-center">
                <i className="fas fa-search fa-2x mb-3 color-1"></i>
                <h1 className="font-1">No Result Found</h1>
                <p>Sorry, but nothing matched your search. Please try again.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchResults;
