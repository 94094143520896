import { combineReducers } from "redux";

import AuthReducer from "./auth";
import ProjectsReducer from "./project";
import InventoryReducer from "./inventory";
import ModelReducer from "./models";
import StaffReducer from "./staff";
import ClientReducer from "./client";
import VendorReducer from "./vendor";
import LeadReducer from "./lead";

const rootReducer = combineReducers({
  auth: AuthReducer,
  project: ProjectsReducer,
  inventory: InventoryReducer,
  models: ModelReducer,
  staff: StaffReducer,
  vendor: VendorReducer,
  lead: LeadReducer,
});

export default rootReducer;
