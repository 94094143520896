import { call, takeLatest, all, put } from "redux-saga/effects";

import * as STAFF_ from "store/constants/staff";
import { getAllStaff, createStaff, updateStaff } from "services/staffServices";
import { SetStaffs } from "store/actions/staff";

function* GetAllStaffRequest(action) {
  const { error, res } = yield call(getAllStaff);
  if (error) return;
  yield put(SetStaffs(res.data));
}

function* CreateStaffRequest(action) {
  const { resolve, reject, data } = action.payload;

  const { error, res } = yield call(createStaff, data);

  if (error) {
    return reject();
  }

  resolve(res.data);
}

function* UpdateStaffRequest(action) {
  const { resolve, reject, data, id } = action.payload;

  const { error, res } = yield call(updateStaff, id, data);

  if (error) {
    return reject();
  }

  resolve(res.data);
}

/** ACTION WATCHERS */
function* ActionWatcher() {
  yield takeLatest(STAFF_.GET_ALL_STAFF, GetAllStaffRequest);
  yield takeLatest(STAFF_.CREATE_STAFF_REQUEST, CreateStaffRequest);
  yield takeLatest(STAFF_.UPDATE_STAFF_REQUEST, UpdateStaffRequest);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
