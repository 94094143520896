import React from "react";
import "./App.css";
import "aos/dist/aos.css";

import {ReactNotifications} from "react-notifications-component";
import {Redirect, Route, Switch} from "react-router-dom";

import Admin from "pages/admin";
import HomerBuyer from "pages/homebuyer";
import ContactUs from "pages/ContactUs";

import TourImage from "components/TourImage";
//import TourHD from "components/TourHD";
import ProtectedRoute from "components/routes/protectedRoute";
import UnprotectedRoute from "components/routes/unprotectedRoute";

import "react-notifications-component/dist/theme.css";
import "animate.css";

const App = (props) => {
    return (
        <>
            <ReactNotifications/>
            <Switch>
                <Route path="/" component={HomerBuyer}/>
                <Route>
                    <Redirect to="/"/>
                </Route>
            </Switch>
        </>
    );
};

export default App;
