import API from "./";

export const createItemService = (reqBody) =>
  API.post("/inventory/items/", reqBody);

export const updateInventoryItemService = (id, reqBody) =>
  API.put(`/inventory/items/${id}/`, reqBody);

//All Inventory Items
export const getInventoryItemsService = () => API.get("/inventory/items/");

//Specific Inventory Item
export const getInventoryItemService = (id) =>
  API.get(`/inventory/items/${id}/`);

export const getInventoryColorOptionsService = () =>
  API.get("/inventory/items/colors/");

export const getInventoryStyleOptionsService = () =>
  API.get("/inventory/items/styles/");

export const getInventoyItemTypeOptionsService = () =>
  API.get("/inventory/items/types/");

export const deleteInventoryItem = (id) =>
  API.delete(`/inventory/items/${id}/`);
