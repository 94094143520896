import * as ProjectActions from "../constants/project";

export const CreateProjectRequest = (payload) => ({
  type: ProjectActions.CREATE_PROJECT_REQUEST,
  payload,
});

export const CreateProjectSucccess = (payload) => ({
  type: ProjectActions.CREATE_PROJECT_SUCCESS,
  payload,
});

export const GetProjects = () => ({
  type: ProjectActions.GET_PROJECTS,
});

export const SetProjects = (payload) => ({
  type: ProjectActions.SET_PROJECTS,
  payload,
});

export const GetActiveProject = (payload) => ({
  type: ProjectActions.GET_ACTIVE_PROJECT,
  payload,
});

export const SetActiveProject = (payload) => ({
  type: ProjectActions.SET_ACTIVE_PROJECT,
  payload,
});

export const UpdateProjectRequest = (payload) => ({
  type: ProjectActions.UPDATE_PROJECT_REQUEST,
  payload,
});

export const UpdateProjectSuccess = (payload) => ({
  type: ProjectActions.UPDATE_PROJECT_SUCCESS,
  payload,
});

export const DeleteProjectRequest = (payload) => ({
  type: ProjectActions.DELETE_PROJECT_REQUEST,
  payload,
});

export const DeleteProjectFailed = (payload) => ({
  type: ProjectActions.DELETE_PROJECT_FAILED,
  payload,
});
