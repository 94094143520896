/*
 * FloorPlanModal
 *  - THIS COMPONENT IS USED IN HOMEBUYER SIDE
 *  - This component is used for showing all the floor plans of the model
 *  - images if the floor plan can be zoomed on hover
 */

import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { GetImageUrl } from "helpers/aux";

import ReactImageMagnify from "react-image-magnify";

const FloorPlanModal = (props) => {
  const {
    isOpen,
    setModalState,
    activeModel: { name },
    modelName,
    floor_plans = [],
    //floorPlanImage,
  } = props;

  return (
    <Modal show={isOpen} centered className="floor-plan">
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-4 pb-5">
        <div className="container-fluid">
          {/*<h1 className="">Belterra Estates</h1>*/}
          <span className="me-3">{name || modelName || "No model name"}</span>
          {/*
          <i
            id="like-button"
            className="fa me-2 fa-heart-o not-liked"
            aria-hidden="true"
          ></i>
          <a
            href="#"
            className="color-1"
            data-bs-target="#share"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            <i className="fas fa-share-alt" aria-hidden="true"></i>
          </a>
          */}

          {floor_plans.map((floor_plan, index) => {
            return (
              <div className="row" key={index}>
                <div className="col my-4">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        src: GetImageUrl(floor_plan.file),
                        className: "img-fluid",
                        isFluidWidth: true,
                      },
                      largeImage: {
                        src: GetImageUrl(floor_plan.file),
                        width: 1000,
                        height: 1000,
                      },
                      isHintEnabled: true,
                    }}
                  />
                  {/*
                  <img
                    className="img-fluid"
                    src={GetImageUrl(floor_plan.file)}
                  />
                    */}
                </div>
              </div>
            );
          })}

          {!floor_plans.length && (
            <div className="row">
              <div className="col my-4">
                <div className="text-center" style={{ padding: "20px" }}>
                  <h3>No Floor plan!</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeModel: state.models.active,
});

export default connect(mapStateToProps)(FloorPlanModal);
