/*
 * AddUserModal
 *  - THIS COMPONENT IS USED IN SUPERADMIN SIDE
 *  - This component is used for adding user in the staff management
 *  - modal is shown upon clicking "Add User" button
 */

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";

import { CreateStaffRequest } from "store/actions/staff";
import {
  CreateSuccessNotification,
  CreateErrorNotification,
} from "helpers/notification";

import PhoneInput from "components/input/PhoneInput";

const AddUserModal = (props) => {
  const { isOpen, setModalState, refresh } = props;

  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const [isRegistering, setIsRegistering] = useState(false);

  const CreateStaffData = (data) => {
    setIsRegistering(true);

    new Promise((resolve, reject) => {
      dispatch(CreateStaffRequest({ resolve, reject, data }));
    })
      .then(() => {
        CreateSuccessNotification("Successfully created staff!");
        setIsRegistering(false);
        setModalState(false);
      })
      .catch((err) => {
        CreateErrorNotification(err.data);
      })
      .finally(() => {
        refresh(true);
      });
  };

  return (
    <Modal show={isOpen} centered className="">
      <Modal.Header className="px-4">
        <h2 className="form-title mb-0 add-user-modal-header-text text-uppercase">
          {" ADD Users "}
        </h2>
        <button
          type="button"
          className="btn-close"
          onClick={() => setModalState(false)}
        ></button>
      </Modal.Header>
      <Modal.Body className="form-modal px-4 pb-5">
        <form className="row mb-4" onSubmit={handleSubmit(CreateStaffData)}>
          <div className="row mb-3">
            <div className="col-12 mb-4">
              <label className="form-label">Email Address</label>
              <input
                autoFocus
                type="text"
                className="form-control"
                placeholder="Email"
                disabled={isRegistering}
                ref={register}
                name="email"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                placeholder="First Name"
                disabled={isRegistering}
                ref={register}
                name="first_name"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                placeholder="Last Name"
                disabled={isRegistering}
                ref={register}
                name="last_name"
              />
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">Phone Number</label>
              <PhoneInput
                ref={register}
                name="phone"
                className="form-control"
                placeholder="Phone Number"
                type="tel"
              />
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Address</label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Address"
              disabled={isRegistering}
              ref={register}
              name="address"
            />
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Account Type</label>
            <select
              ref={register}
              id="role"
              name="role"
              className="form-control form-control-select"
              required
            >
              <option value="" disabled="" hidden={true}>
                Select
              </option>
              <option value="ADMIN">Admin</option>
              <option value="STAFF">Staff</option>
            </select>
          </div>
          <div className="col-12 mb-4">
            <label className="form-label">Temporary Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              autoComplete="off"
              disabled={isRegistering}
              ref={register}
              name="password"
            />
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <button
                className="btn btn-solid-blue mr-2 w-100 rounded-0"
                disabled={isRegistering}
              >
                {"ADD USER"}
              </button>
            </div>
            <div className="col-md-6 mb-3">
              <button
                type="button"
                className="btn btn-outline-blue w-100 rounded-0"
                disabled={isRegistering}
                onClick={(e) => (e.preventDefault(), setModalState(false))}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeModel: state.models.active,
});

export default connect(mapStateToProps)(AddUserModal);
