import API from "./";

export const createModelService = (projectId, payload) =>
  API.post(`/projects/${projectId}/models/`, payload);

export const getModelsService = (projectId) =>
  API.get(`/projects/${projectId}/models/`);

export const GetProjectModels = (projectId) =>
  API.get(`/projects/${projectId}/models/`);

export const UpdateModelService = (modelId, reqBody) =>
  API.put(`/projects/models/${modelId}/`, reqBody);

export const DeleteModelService = (modelId) =>
  API.delete(`/projects/models/${modelId}/`);

/*
 * FAVORITE MODELS
 */

export const GetFavoriteModels = () => API.get("/projects/favorites/");
export const GetFavoriteModel = (id) => API.get(`/projects/favorites/${id}/`);

export const AddToFavoriteModel = (modelId, reqBody = { tour_data: {} }) =>
  API.post(`/projects/models/${modelId}/favorites/`, reqBody);

export const RemoveFavoriteModel = (modelId) =>
  API.delete(`/projects/models/${modelId}/favorites/`);

export const UpdateFavoriteModel = (modelId, reqBody) =>
  API.patch(`/projects/favorites/${modelId}/`, reqBody);

export const ShareFavoriteModelSMSService = (modelId, reqBody) =>
  API.post(`/projects/favorites/${modelId}/share-sms/`, reqBody);
