/**
 * HomeModelSlider
 *  - This component is used for the slider images of the model homes
 *  - Used SWIPER component for the slides
 *
 *  - PROPS
 *    - images (Array of Objects)
 */

import React from "react";
import { GetImageUrl } from "helpers/aux";

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation]);

const HomeModelSlider = (props) => {
  const { images = [], showImage } = props;
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  if (!images.length) {
    return null;
  }

  const onClickShowImage = (e, image) => {
    e.preventDefault();
    showImage(image);
  }

  return (
    <div className="my-5">
      <Swiper
        spaceBetween={20}
        direction="horizontal"
        slidesPerView={3}
        autoPlay={{ delay: 500 }}
        className="swiper-container"
        loop={false}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {images.map((img, index) => {
          return (
            <SwiperSlide key={index} className="text-center">
              <img
                src={GetImageUrl(img.image)}
                className="img-fluid"
                alt="..."
                onClick={(e) => onClickShowImage(e, img)}
              />
              <span>{img.name.toUpperCase()}</span>
            </SwiperSlide>
          );
        })}
        <div ref={navigationPrevRef} className="swiper-button-prev gradient-prev" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-ccd264ba4bdfd02b" aria-disabled="false"></div>
        <div ref={navigationNextRef} className="swiper-button-next gradient-next" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-ccd264ba4bdfd02b" aria-disabled="true"></div>
      </Swiper>
    </div>
  );
};

export default HomeModelSlider;
