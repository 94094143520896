export const LOGIN_REQUEST = `LOGIN_REQUEST`;

export const FORGOT_PASSWORD_REQUEST = `FORGOT_PASSWORD_REQUEST`;
export const VERIFY_FORGOT_PASSWORD_CODE = `VERIFY_FORGOT_PASSWORD_CODE`;
export const CHANGE_PASSWORD = `CHANGE_PASSWORD`;

export const REGISTER_REQUEST = `REGISTER_REQUEST`;
export const VERIFY_REGISTER_REQUEST = `VERIFY_REGISTER_REQUEST`;
export const RESEND_REGISTER_VERIFY_CODE = `RESEND_REGISTER_VERIFY_CODE`;

export const UPADATE_USER_SETTINGS_REQUEST = `UPADATE_USER_SETTINGS_REQUEST`;
export const UPDATE_USER_PASSWORD_REQUEST = `UPDATE_USER_PASSWORD_REQUEST`;

export const LOGOUT = `LOGOUT`;
export const SET_SHOW_LOGIN_MODAL = `SET_SHOW_LOGIN_MODAL`;

export const REGISTER_VENDOR_REQUEST = `REGISTER_VENDOR_REQUEST`;
// export const VERIFY_REGISTER_VENDOR_REQUEST = `VERIFY_REGISTER_VENDOR_REQUEST`;
// export const RESEND_REGISTER_VENDOR_VERIFY_CODE = `RESEND_REGISTER_VENDOR_VERIFY_CODE`;
