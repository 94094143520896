import React, {useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AOS from "aos";

import UnprotectedRoute from "components/routes/unprotectedRoute";
import ProtectedRoute from "components/routes/protectedRoute";

import Vendor from "./vendor";
import VendorRegistration from "./register-vendor";
import VendorContactForm from "./lets-talk";
import Listings from "./listings";
import Registration from "./register";
//import Dashboard from "./dashboard";
import ForgotPassword from "./forgotPassword";
import SavedHomes from "./SavedHomes";
import RequestShowing from "./RequestShowing";
import Settings from "./Settings";
import CommunityMap from "./CommunityMap";

import Tour from "./tour";
import ToC from "./toc";
import ThankYou from "./thank-you";

/**
 * Note: Login modal is in ListingsNavbar Component
 * path: /components/navbar/listings.js
 */

export default (props) => {
    useEffect(() => {
        AOS.init({
            easing: "ease-in",
        });
    }, []);

    return (
        <Switch>
            <UnprotectedRoute path="/home" component={Vendor}/>
            <UnprotectedRoute path="/toc" component={ToC}/>
            <UnprotectedRoute path="/thank-you" component={ThankYou}/>
            <UnprotectedRoute
                path="/lets-talk"
                component={VendorContactForm}
            />
            <Route render={() => <Redirect to="/home"/>}/>
        </Switch>
    );
};
