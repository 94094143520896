import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import HomebuyerNavbar from "components/navbar/homebuyerNavbar";
import HomeBuyerFooter from "components/footer/homebuyerFooter";
// import ChangePassword from "./ChangePassword";
import PhoneInput from "components/input/PhoneInput";

import { getUserSettings } from "services/authServices";
import { UpdateUserSettingRequest } from "store/actions/auth";
import { CreateSuccessNotification } from "helpers/notification";

const AccountSettings = (props) => {
  // TODO: remove; for UI testing only
  const [user, setUser] = useState({
    first_name: "Ryan",
    last_name: "Smith",
    email: "ryansmith@gmail.com",
    address: "12 StreetName Hilldrive Ave FL",
    phone: "555154126",
  });
  // const [user, setUser] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();

  // useEffect(async () => {
  //   const { err, res } = await getUserSettings();
  //   if (err) {
  //   }

  //   setUser(res.data);
  // }, []);

  const UpdateUserProfile = (data) => {
    // TODO: revert after api fix!
    // setSubmitting(true);
    
    // new Promise((resolve, reject) => {
    //   dispatch(UpdateUserSettingRequest({ data, resolve, reject }));
    // })
    //   .then(() => {
    //     CreateSuccessNotification("Successfully updated settings!");
    //     //alert("Successfully updated settings!");
    //     setIsUpdating(false);
    //   })
    //   .finally(() => {
    //     setSubmitting(false);
    //   });
  };

  return (
    <>
      <HomebuyerNavbar />
      <section
        className="with-bg"
        // style={{ backgroundImage: 'url("/img/magnolia-facade_lg.jpeg")' }}
        style={{ backgroundImage: 'url("/img/common-bg.jpeg")' }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div
                className="box-transparent p-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="form-title mb-4">Profile Settings</h1>
                <Switch>
                  {/* <Route
                    path={props.match.url + "/change-password"}
                    component={ChangePassword}
                  /> */}
                  <Route path={props.match.url}>
                    <form
                      className="row mb-4"
                      onSubmit={handleSubmit(UpdateUserProfile)}
                    >
                      <div className="col-lg-6">
                        <label htmlFor="" className="form-label">
                          First Name
                        </label>
                        <input
                          autoFocus
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          defaultValue={user.first_name}
                          disabled={!isUpdating}
                          ref={register}
                          name="first_name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="" className="form-label">
                          Last Name
                        </label>
                        <input
                          disabled={!isUpdating}
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          defaultValue={user.last_name}
                          ref={register}
                          name="last_name"
                        />
                      </div>
                      <div className="row gap-4 ">
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            Email address
                          </label>
                          <input
                            disabled={!isUpdating}
                            type="email"
                            className="form-control"
                            placeholder="Enter Email Address"
                            defaultValue={user.email}
                            ref={register}
                            name="email"
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            Address
                          </label>
                          <input
                            disabled={!isUpdating}
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            defaultValue={user.address}
                            ref={register}
                            name="address"
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <label htmlFor="" className="form-label">
                            Phone Number
                          </label>
                          <PhoneInput
                            disabled={!isUpdating}
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            defaultValue={user.phone}
                            ref={register}
                            name="phone"
                          />
                          <span style={{ fontSize: "0.75em" }}>
                            Format: "+[country code]999999"
                          </span>
                        </div>
                        {/* PASSWORD CONTENT */}
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            CURRENT PASSWORD
                          </label>
                          <div
                            className="input-group pass-word"
                            id="show_hide_password_c"
                          >
                            <input
                              ref={register}
                              name="password1" // TODO: check model name
                              className="form-control"
                              type={showPassword.password ? "text" : "password"}
                              placeholder="Enter Current Password"
                              style={
                                errors?.password1 ? { borderColor: "red" } : {}
                              }
                            />
                            <div className="input-group-addon">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowPassword({
                                    ...showPassword,
                                    password: !showPassword.password,
                                  });
                                }}
                              >
                                <i
                                  className={
                                    showPassword.password
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            NEW PASSWORD
                          </label>
                          <div
                            className="input-group pass-word"
                            id="show_hide_password_c"
                          >
                            <input
                              ref={register}
                              name="password2" // TODO: check model name
                              className="form-control"
                              type={showPassword.password ? "text" : "password"}
                              placeholder="Enter New Password"
                              style={
                                errors?.password1 ? { borderColor: "red" } : {}
                              }
                            />
                            <div className="input-group-addon">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowPassword({
                                    ...showPassword,
                                    password: !showPassword.password,
                                  });
                                }}
                              >
                                <i
                                  className={
                                    showPassword.password
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            RE-ENTER NEW PASSWORD
                          </label>
                          <div
                            className="input-group pass-word"
                            id="show_hide_password_c"
                          >
                            <input
                              ref={register}
                              name="password3" // TODO: check model name
                              className="form-control"
                              type={showPassword.password ? "text" : "password"}
                              placeholder="Re-Enter New Password"
                              style={
                                errors?.password1 ? { borderColor: "red" } : {}
                              }
                            />
                            <div className="input-group-addon">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowPassword({
                                    ...showPassword,
                                    password: !showPassword.password,
                                  });
                                }}
                              >
                                <i
                                  className={
                                    showPassword.password
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* PASSWORD CONTENT */}
                        <div className="d-grid">
                          <button
                            disabled={submitting}
                            className="btn btn-primary"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HomeBuyerFooter />
    </>
  );
};

export default AccountSettings;
