import API from "./";

export const createProjectService = (reqBody) =>
  API.post("/projects/", reqBody);

//GET all projects
export const getProjectsService = () => API.get("/projects/");

//GET specific project
export const getProjectService = (id) => API.get(`/projects/${id}/`);

export const UpdateProjectService = (id, reqBody) =>
  API.put(`/projects/${id}/`, reqBody);

export const DeleteProjectService = (id) => API.delete(`/projects/${id}/`);

export const searchProjectService = (q) => API.get(`/projects/search?q=${q}`);

export const GetMeetingCountries = () =>
  API.get("/projects/meeting-countries/");

export const GetMeetingTypes = () => API.get("/projects/meeting-types");

export const CreateRequestMeeting = (reqBody) =>
  API.post("/projects/meetings/", reqBody);
