import * as STAFF_ from "store/constants/staff";

export const GetAllStaff = () => ({
  type: STAFF_.GET_ALL_STAFF,
});

export const SetStaffs = (payload) => ({
  type: STAFF_.SET_STAFFS,
  payload,
});

export const CreateStaffRequest = (payload) => ({
  type: STAFF_.CREATE_STAFF_REQUEST,
  payload,
});

export const CreateStaffSuccess = (payload) => ({
  type: STAFF_.CREATE_STAFF_SUCCESS,
  payload,
});

export const UpdateStaffRequest = (payload) => ({
  type: STAFF_.UPDATE_STAFF_REQUEST,
  payload,
});
