import API from "./";

/**
 * reqBody : {
 *  name,
 *  email,
 *  phone,
 *  company,
 *  notes,
 *  referral_code
 * }
 */
export const leadCreate = (data) => API.post("/leads/", data);
