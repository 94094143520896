/*
 * UnprotectedRoute
 *  - This component is used for the public routes of the application
 *  - It works like a modified Route component from react-router-dom
 *
 *
 *  - If the user is authenticated it would redirect the user to the coresponsing page depending on its role
 *
 */

import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setAuthHeaderToken, unsetAuthHeaderToken } from "services";
import { userDetailRequest } from "store/actions/user";

/*
 * props
 *  - hardUnprotect [BOOL] - will return the component wheter authenticated or not
 */

const UnprotectedRoute = (props) => {
  const { component: Component, ...rest } = props;
  const [auth, setAuth] = useState(false);
  const [ready, setIsReady] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setAuthHeaderToken();

    new Promise((resolve, reject) => {
      dispatch(userDetailRequest({ resolve, reject }));
    })
      .then((res) => {
        const { is_superuser } = res;
        setIsSuperAdmin(is_superuser);
        setAuth(true);
      })
      .catch(() => unsetAuthHeaderToken())
      .finally(() => {
        setIsReady(true);
      });
  }, []);

  if (!ready)
    return (
      <h1
        style={{
          textAlign: "center",
          paddingTop: "16%",
          fontFamily: "Open Sans",
        }}
      >
        Loading...
      </h1>
    );

  if (isSuperAdmin) {
    return <Redirect to="/admin" />;
  }

  if (props.hardUnprotect) {
    return <Component {...props} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;

  /*
  return (
    <Route
      {...rest}
      render={(props) => {
        return !auth ? (
          <Component {...props} />
        ) : isSuperAdmin ? (
          <Redirect to="/admin" />
        ) : (
          <Redirect to="/home" />
        );
      }}
    />
  );
  */
};

export default UnprotectedRoute;
