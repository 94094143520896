import { call, takeLatest, all, put, takeEvery } from "redux-saga/effects";

import * as actionTypes from "store/constants/models";
import {
  CreateModelSuccess,
  SetFavoriteModels,
  UnfavoriteModel,
  FavoriteModel,
  GetFavoriteModels,
  SetProjectModels,
  DeleteModelFailed,
} from "store/actions/models";

import * as modelServices from "services/modelServices";
import JSONFromSuperAdmin from "mock-data/JSONFromSuperAdmin";

function* CreateModelFlow(action) {
  const { payload, resolve, reject, projectId } = action.payload;

  const { err, res } = yield call(
    modelServices.createModelService,
    projectId,
    payload
  );

  if (err) {
    return reject(err);
  }

  resolve();
  yield put(CreateModelSuccess(res.data));
}

function* GetFavoriteModelsFlow() {
  const { res, err } = yield call(modelServices.GetFavoriteModels);

  if (err) {
    console.log(err);
    return;
  }

  yield put(SetFavoriteModels(res.data));
}

function* FavoriteModelFlow(action) {
  const { unity_body, id, layout_name } = action.payload;

  let reqBody = { tour_data: unity_body ? unity_body : {}, layout_name };
  /*
   * FOR TESTING PURPOSES
   *  reqBody = { tour_data: JSONFromSuperAdmin ? JSONFromSuperAdmin : {} };
   */

  const { res, err } = yield call(
    modelServices.AddToFavoriteModel,
    id,
    reqBody
  );

  yield put(GetFavoriteModels());

  if (err) {
    console.log(err);
    yield put(UnfavoriteModel(action.payload)); // reverse if there's a problem
  }
}

function* UnfavoriteModelFlow(action) {
  const { res, err } = yield call(
    modelServices.RemoveFavoriteModel,
    action.payload.id
  );

  if (err) {
    console.log(err);
    yield put(FavoriteModel(action.payload)); // reverse if there's a problem
  }
}

function* UpdateFavoriteModelFlow(action) {
  const { favoriteModelId, data } = action.payload;
  const { res, err } = yield call(
    modelServices.UpdateFavoriteModel,
    favoriteModelId,
    { ...data }
  );

  if (err) {
    console.log(err);
    return;
  }
}

function* GetProjectModelsFlow(action) {
  const { projectId } = action.payload;
  const { res, err } = yield call(modelServices.GetProjectModels, projectId);

  if (err) {
    console.log(err);
    return;
  }

  yield put(SetProjectModels({ projectId, models: res.data }));
}

function* UpdateModelFlow(action) {
  const { resolve, reject, modelId, payload } = action.payload;

  const { err, res } = yield call(
    modelServices.UpdateModelService,
    modelId,
    payload
  );

  if (err) {
    reject(err.data);
    return;
  }

  resolve(res.data);
}

function* DeleteModelFlow(action) {
  const { id } = action.payload;

  const { err, res } = yield call(modelServices.DeleteModelService, id);

  if (err) {
    yield put(DeleteModelFailed(action.payload));
    return;
  }
}

function* ActionWatcher() {
  yield takeLatest(actionTypes.CREATE_MODEL_REQUEST, CreateModelFlow);
  yield takeLatest(actionTypes.GET_FAVORITE_MODELS, GetFavoriteModelsFlow);
  yield takeLatest(actionTypes.FAVORITE_MODEL, FavoriteModelFlow);
  yield takeLatest(actionTypes.UNFAVORITE_MODEL, UnfavoriteModelFlow);
  yield takeEvery(actionTypes.UPDATE_FAVORITE_MODEL, UpdateFavoriteModelFlow);
  yield takeEvery(actionTypes.GET_PROJECT_MODELS, GetProjectModelsFlow);
  yield takeLatest(actionTypes.UPDATE_MODEL_REQUEST, UpdateModelFlow);
  yield takeLatest(actionTypes.DELETE_MODEL_REQUEST, DeleteModelFlow);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
