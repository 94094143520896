/*
 * PhoneInput
 *  - this component is used in all forms that has phone input
 *  - it automatically formats the phone number in US format as the user types
 */

import { useState, forwardRef, useEffect } from "react";

const PhoneInput = (props, ref) => {
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!phone) {
      setPhone(props.defaultValue);
    }
  }, [props]);

  const phoneChangeHandler = (e) => {
    let value = e.target.value;

    let newValue = value.replace(/\D/g, "");

    if (!newValue) {
      setPhone(newValue);
      return;
    }

    if (parseInt(newValue.charAt(0)) !== 1) {
      setPhone("+1" + newValue);
      return;
    }

    if (newValue.length < 5) {
      setPhone(`+1${newValue.slice(1, 4)}`);
      return;
    }

    setPhone(`+1(${newValue.slice(1, 4)})-${newValue.slice(4)}`);
  };

  return (
    <input
      ref={ref}
      {...props}
      value={phone}
      onChange={phoneChangeHandler}
      maxLength={15}
    />
  );
};

export default forwardRef(PhoneInput);
