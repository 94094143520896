import { call, takeLatest, all, put } from "redux-saga/effects";

import { leadCreate } from "services/leadServices";
import * as LEAD_ from "store/constants/lead";

export function* LeadsCreateFlow(action) {
  const { resolve, reject, data } = action.payload;

  const { err, res } = yield call(leadCreate, data);
  if (err) {
    return reject(err);
  }
  resolve();
}

function* ActionWatcher() {
  yield takeLatest(LEAD_.LEADS_CREATE, LeadsCreateFlow);
}

function* Watcher() {
  yield all([ActionWatcher()]);
}

export default Watcher;
