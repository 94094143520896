import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Switch, Route} from "react-router-dom";
import {useForm} from "react-hook-form";
import LoadingOverlay from "react-loading-overlay";

import HomebuyerNavbar from "components/navbar/homebuyerNavbar";
import HomeBuyerFooter from "components/footer/homebuyerFooter";

import {CreateSuccessNotification} from "helpers/notification";

import {leadsCreateRequest} from "store/actions/lead";

const VendorContactForm = (props) => {
    const {register, handleSubmit, errors} = useForm();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://www.google.com/recaptcha/api.js";

        script.async = true;

        document.body.appendChild(script);

        return () => {
            // clean up the script when the component in unmounted
            document.body.removeChild(script);
        };
    }, []);

    const SubmitForm = (data) => {

        setSubmitting(true);

        return new Promise((resolve, reject) => {
            dispatch(leadsCreateRequest({data, resolve, reject}));
        })
            .then(() => {
                CreateSuccessNotification("Success!");
                setTimeout(() => {
                    window.location = "/thank-you"
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                alert("Sending Failed! Try Again.");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

    return (
        <>
            <HomebuyerNavbar/>
            <LoadingOverlay active={showLoadingOverlay} spinner text="Please wait...">
                <section
                    className="with-bg"
                    style={{backgroundImage: 'url("/img/magnolia-facade_lg.jpeg")'}}
                >
                    <div className="container-fluid">
                        <div className="row justify-content-center ">
                            <div className="col-lg-8">
                                <div
                                    className="box-transparent p-5"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >
                                    <div className="text-end fs-19 d-block">
                                        <a href="/" className="txt-color-dark"><i
                                            className="fa fa-times" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6 order-last order-md-first ">

                                            <h1 className="text-lg-center form-title text-capitalize mb-4">
                                                A representative will <br/> contact you shortly.
                                            </h1>
                                            <ul className="list-unstyled text-md-center">
                                                <li className="mb-2">
                                                    <strong>Virtual Reality Inc.</strong>
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fa fa-envelope me-2"></i> 1520 East
                                                    Sunrise Blvd. Fort Lauderdale, FL 33304
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone-alt me-2"></i> (954)
                                                    448-1074
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 order-first order-md-last">
                                            <h1 className="form-title">Let's Get Started Today </h1>
                                            <p className="mb-4">
                                                Fill out the form below and one of our specialists will
                                                contact you.
                                            </p>
                                            <form
                                                className="row gap-4 mb-5"
                                                onSubmit={handleSubmit(SubmitForm)}
                                            >
                                                <div className="col-12">
                                                    <label for="" className="form-label">
                                                        Name
                                                    </label>
                                                    <input
                                                        ref={register}
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        placeholder="Enter Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <label for="" className="form-label">
                                                        Phone
                                                    </label>
                                                    <input
                                                        ref={register}
                                                        name="phone"
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        placeholder="Enter Phone Number"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <label for="" className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        ref={register}
                                                        name="email"
                                                        type="email"
                                                        className="form-control"
                                                        id=""
                                                        placeholder="Enter Email Address"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <label for="" className="form-label">
                                                        Company
                                                    </label>
                                                    <input
                                                        ref={register}
                                                        name="company"
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        placeholder="Enter Company"
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <label for="" className="form-label">
                                                        Notes
                                                    </label>
                                                    <textarea
                                                        ref={register}
                                                        name="notes"
                                                        id=""
                                                        cols=""
                                                        rows="5"
                                                        placeholder="Enter Notes"
                                                        className="form-control py-1"
                                                    ></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <label for="" className="form-label">
                                                        Referral Code
                                                    </label>
                                                    <input
                                                        ref={register({required: false, maxLength: 255})}
                                                        name="referral_code"
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        placeholder="Enter Referral Code"
                                                    />

                                                    <span className="text-danger">{errors.referral_code && "Referral code must be 6 characters long."}</span>
                                                </div>
                                                <div className="col-12 text-center">
                                                    <div
                                                        className="g-recaptcha"
                                                        data-sitekey="6Ldo6WIeAAAAAKMOfY-BGpKbOg3lkse520aYi2dg"
                                                    ></div>
                                                </div>
                                                <div className="col-12">
                                                    {/* <a
                            href="javascript:void(0)"
                            className="btn btn-primary w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#code-verification"
                          >
                            Send Message
                          </a> */}
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-100"
                                                    >
                                                        Send Message
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
            <HomeBuyerFooter/>
        </>
    );
};

export default VendorContactForm;
