/**
 * TourItemsStylesSlider
 *  - This component is used for the slider images of the model homes
 *
 *  - PROPS
 *    - styles (Array of Objects)
 *    - clickHandler ( Function )  => Functio nthat will
 *
 */

//import { GetImageUrl } from "helpers/aux";

import React from "react";

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation]);

const TourPresetSlider = (props) => {
  const { styles = [], clickHandler, closeSlider, isPreset = false } = props;
  // const { styles = [], clickHandler, isPreset = false } = props;
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  if (!styles.length) {
    return null;
  }

  const styleClickHandler = (e, style) => {
    e.preventDefault();
    clickHandler(style);
  };

  const onPressCloseBtn = (e) => {
    e.preventDefault();
    closeSlider();
  }

  return (
    <div className="container">
      <div className="my-5 pb-5">
        <div style={{
          zIndex: 20,
          textAlign: "right"
        }}>
          <a href="#" onClick={(e) => onPressCloseBtn(e)} className="swiper-close-icon">
            <i className="far fa-times-circle txt-color-gray-60" aria-hidden="true"></i>
          </a>
        </div>
        <Swiper
          spaceBetween={20}
          direction="horizontal"
          slidesPerView={3}
          autoPlay={{ delay: 500 }}
          className="swiper-container"
          loop={false}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {styles.map((style, index) => {
            return (
              <SwiperSlide key={index} className="text-center">
                <img
                  style={{ cursor: isPreset ? "pointer" : "default" }}
                  src={style.imgPreview}
                  className="img-fluid"
                  alt="..."
                  onClick={(e) => styleClickHandler(e, style)}
                />
                <span className="txt-color-white strong">{style.name}</span>
              </SwiperSlide>
            );
          })}
          <div style={{ height: "271px" }} ref={navigationPrevRef} className="swiper-button-prev gradient-prev" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-ccd264ba4bdfd02b" aria-disabled="false"></div>
          <div style={{ height: "271px" }} ref={navigationNextRef} className="swiper-button-next gradient-next" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-ccd264ba4bdfd02b" aria-disabled="true"></div>
        </Swiper>
      </div>
    </div>
  );
};

export default TourPresetSlider;
