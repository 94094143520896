import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import HomebuyerNavbar from "components/navbar/homebuyerNavbar";
import HomeBuyerFooter from "components/footer/homebuyerFooter";
import SuccessComponent from "components/SuccessComponent";

import Search from "./search";
import SearchResults from "./searchResults";
import ModelList from "./modelsList";
import ModelDetails from "./ModelDetails";

const RequestShowingSuccess = (props) => {
  return (
    <section
      className="with-bg"
      style={{ backgroundImage: 'url("/img/bg-success-2.jpg")' }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <SuccessComponent
              message="You have successfully requested a meeting/showing of your selected house model. Kindly wait for our response for further instructions."
              buttonText="GO TO SEARCH RESULTS"
              redirectPath="/"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const Listings = (props) => {
  const query = new URLSearchParams(props.location.search);
  const openLoginModal = query.get("openLoginModal");
  return (
    <>
      <HomebuyerNavbar openLoginModal={openLoginModal} />
      <Switch>
        <Route
          path={props.path + "/request-showing-success"}
          component={RequestShowingSuccess}
        />
        <Route
          path={props.path + "/:communityId/model-detail/:modelId?"}
          component={ModelDetails}
        />
        <Route
          path={props.path + "/models/:projectId?"}
          exact
          component={ModelList}
        />
        <Route path={props.path + "/search"} component={SearchResults} exact />
        {/*
          * Temporarily commented
        <Route path={props.path} component={Search} />
        */}
        {/* <Route path={props.path} component={Search} /> */}

        {/*TEMPORARILY REDIRECTED TO THIS COMMUNITY*/}
        <Route>
          <Redirect to={props.path + "/models/3"} />
        </Route>
      </Switch>
      <HomeBuyerFooter />
    </>
  );
};

export default withRouter(Listings);
