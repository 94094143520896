import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import classNames from "classnames";

import FloorPlanModal from "components/modals/FloorPlanModal";
import ModelImageModal from "components/modals/ModelImageModal";
import HomeModelSlider from "components/slider/HomeModelSlider";

import { getModelsService } from "services/modelServices";

import { SetActiveModel } from "store/actions/models";

const ModelDetails = (props) => {
  const dispatch = useDispatch();
  const [showFloorPlanModal, setShowFloorPlanModal] = useState(false);
  const [showModelImage, setShowModelImage] = useState(false);
  const [modelImage, setModelImage] = useState(null);

  const { activeModel } = props;
  const { modelId, communityId } = props.match.params;

  useEffect(() => {
    window.scrollTo(0, 0);

    const getActiveModel = async () => {
      if (
        Object.entries(activeModel).length === 0 ||
        activeModel.id != modelId
      ) {
        let { res: models, err } = await getModelsService(communityId);
        if (err) {
          props.history.goBack();
        }

        let model = models.data.find((x) => x.id == modelId);

        dispatch(SetActiveModel(model));
      }
    };

    getActiveModel();
  }, [activeModel]);

  const {
    name,
    square_feet,
    bedrooms_count,
    half_bath_count,
    bathrooms_count,
    garage_count,
    stories,
    main_image,
    images,
    floor_plans,
    unity_body,
    id,
  } = activeModel;

  let image_url =
    process.env.NODE_ENV === "development"
      ? `http://localhost:8000${main_image}`
      : main_image;

  if (!Object.entries(activeModel).length) {
    return (
      <section style={{ height: "60vh" }}>
        <h1 style={{ textAlign: "center", paddingTop: "10%" }}>Loading...</h1>
      </section>
    );
  }

  return (
    <>
      <FloorPlanModal
        isOpen={showFloorPlanModal}
        setModalState={setShowFloorPlanModal}
        modelName={name}
        floor_plans={floor_plans}
      />
      <ModelImageModal
        isOpen={showModelImage}
        setModalState={setShowModelImage}
        modelName={name}
        modelImage={modelImage}
      />
      <section className="header-lg p-0 g-0">
        <div className="container-fluid">
          <div className="justify-content-center">
            <div className="col">
              <div
                className={classNames(
                  !main_image &&
                    "d-flex  justify-content-center align-items-center bg-secondary"
                )}
                style={
                  !main_image ? { paddingLeft: "15%", paddingRight: "15%" } : {}
                }
              >
                <img
                  src={main_image ? image_url : "/img/logo-w.svg"}
                  style={
                    main_image ? { objectFit: "cover" } : { height: "50em" }
                  }
                />
              </div>
              <div className="name-lg ps-3 ps-lg-5">
                <div className="mb-0 name mb-2 mb-lg-5">
                  <span>{name || "No model name"}</span>{" "}
                  <i className="fas fa-heart" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5">
        <div className="container">
          <div className="row align-items-center mb-4 mb-xl-0">
            <div className="col-3 col-lg">
              <div className="ico-sm">
                <img className="d-block" src="/img/ico-sm/house.png" />
                <ul className="list-unstyled">
                  <li>Square Feet</li>
                  <li>{square_feet}</li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-lg">
              <div className="ico-sm">
                <img className="d-block" src="/img/ico-sm/bed.png" />
                <ul className="list-unstyled">
                  <li>Bedrooms</li>
                  <li>{bedrooms_count}</li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-lg">
              <div className="ico-sm">
                <img className="d-block" src="/img/ico-sm/shower.png" />
                <ul className="list-unstyled">
                  <li>Bathrooms</li>
                  <li>{bathrooms_count}</li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-lg">
              <div className="ico-sm">
                <img className="d-block" src="/img/ico-sm/bath.png" />
                <ul className="list-unstyled">
                  <li>Half Baths</li>
                  <li>{half_bath_count}</li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-lg">
              <div className="ico-sm">
                <img className="d-block" src="/img/ico-sm/garage.png" />
                <ul className="list-unstyled">
                  <li>Garages</li>
                  <li>{garage_count}</li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-lg">
              <div className="ico-sm">
                <img className="d-block" src="/img/ico-sm/stories.png" />
                <ul className="list-unstyled">
                  <li>Stories</li>
                  <li>{stories}</li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-lg">
              <div
                className="ico-sm"
                style={{ cursor: "pointer" }}
                onClick={() => setShowFloorPlanModal(true)}
              >
                <img className="d-block" src="/img/ico-sm/floor-plan.png" />
                <ul className="list-unstyled">
                  <li>Floorplan</li>
                  <li>&nbsp;</li>
                </ul>
              </div>
            </div>
          </div>
          <HomeModelSlider images={images} 
            showImage={(image) => {
              setShowModelImage(true);
              setModelImage(image);
            }}
          />
          {unity_body || name.toLowerCase() === "magnolia" ? (
            <a
              href="#"
              className="btn btn-primary  my-3"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.history.push(
                  `/tour-360?modelId=${id}&communityId=${communityId}`
                );
              }}
            >
              Take a tour
            </a>
          ) : (
            <a
              href="#"
              // className="btn btn-primary my-3"
              className="btn btn-secondary my-3 disabled"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                alert("This part is under construction");
              }}
            >
              Coming soon
            </a>
          )}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeModel: state.models.active,
});

export default connect(mapStateToProps)(ModelDetails);
